<template>
  <div ref="content">
    <web-detail v-if="showWeb" />
    <mobile-detail v-if="showMobile" />
  </div>
</template>
<script>
import { isMobile } from '../../util/common'
import MobileDetail from './mobile/index.vue'
import WebDetail from './web/index.vue'

export default {
  name: 'Detail',
  components: {
    MobileDetail,
    WebDetail
  },
  mounted () {
    this.onWindowResized()
    window.addEventListener('resize', this.onWindowResized, true)
  },
  data () {
    return {
      showMobile: false,
      showWeb: false
    }
  },
  methods: {
    onWindowResized: function () {
      if (document.fullscreenElement || document.msFullscreenElement || document.webkitFullscreenElement
          || document.mozFullScreenElement) {
        return
      }
      if (!isMobile()) {
        this.showWeb = true
        this.showMobile = false
      } else {
        this.showWeb = false
        this.showMobile = true
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
