<template>
  <div class="main" ref="mainContent">
    <player
      ref="player"
      :speed="speed"
      :current="currentTime"
      :duration="docDuration"
      :slideList="slideList"
      :videoWidth="videoWidth"
      :videoHeight="videoHeight"
      :hasLink="hasLink"
      :linkInfo="linkInfo"
      :playEnded="playEnded"
      @playing="onPlayerPlaying"
      @pause="onPlayerPause"
      @ended="onPlayerEnded"
      @error="onPlayerError"
      @waiting="onPlayerWaiting"
      @seekTime="onPlayerSeekTime"
      @playControl="onPlayerPlayControl"
      @switchSpeed="onPlayerSwitchSpeed"
      @videoSize="onPlayerVideoSize"
      @linkClick='linkClick'
      />
    <div class="title">
      <div>{{ title }}</div>
      <img v-if="testEnv" class="top-test-icon" src="../../../assets/icon_test.svg" />
    </div>
    <div class="center-content">
      <div v-show="!transcribeOn">
        <div class="info">
          <img v-if="avatarImage != null && avatarImage !== ''" class="avatar-img" :src="avatarImage" />
          <div class="avatar" v-else>{{ avatar }}</div>
          <div class="meta">
            <div class="name">{{ author }}</div>
            <div class="time">{{ time }}</div>
          </div>
          <div class="share-button" @click="clickShareButton"></div>
        </div>
        <brand class="brand" />
      </div>
      <div v-show="transcribeOn" class="doc-content" ref="docContent">
        <div class="section-top"></div>
        <template v-for="(block, index1) in textResult">
          <div v-if="block.type == 'wordList'" class="section" :key="index1">
            <span
              v-for="word in block.words"
              :key="word.id"
              @click="clickWord(word)"
              :id="word.id"
              :data-audio-start="word.audioStart"
              :data-audio-end="word.audioEnd"
              :class="word.className"
              :style="word.style"
              >{{ word.word }}</span
            >
          </div>
          <h1 v-else-if="block.type == 'h1'" :key="index1">{{ block.title }}</h1>
        </template>
        <div v-if="!hasText" class="no-text">
          <img src="../../../assets/detail/doc-notext.svg" />
          <div>文字稿为空</div>
        </div>
      </div>
    </div>
    <div class="toolbar">
      <button class="speed-button" @click="clickSpeedButton">
        <img v-if="speed === 1.2" src="../../../assets/detail/speed-1.2-button.svg" />
        <img v-else-if="speed === 1.5" src="../../../assets/detail/speed-1.5-button.svg" />
        <img v-else-if="speed === 1.7" src="../../../assets/detail/speed-1.7-button.svg" />
        <img v-else-if="speed === 2" src="../../../assets/detail/speed-2.0-button.svg" />
        <img v-else src="../../../assets/detail/speed-1.0-button.svg" />
      </button>
      <button class="backward-button" @click="clickBackwardButton">
        <img src="../../../assets/detail/backward-button.svg" />
      </button>
      <button class="play-button" @click="clickPlayButton">
        <img v-if="isPlaying" src="../../../assets/detail/pause-button.svg" />
        <img v-else src="../../../assets/detail/play-button.svg" />
      </button>
      <button class="forward-button" @click="clickForwardButton">
        <img src="../../../assets/detail/forward-button.svg" />
      </button>
      <button class="subscribe-button" @click="clickTranscribeButton">
        <img v-if="transcribeOn" src="../../../assets/detail/transcribe-on-button.svg" />
        <img v-else src="../../../assets/detail/transcribe-off-button.svg" />
      </button>
    </div>
    <div v-if="isLoading" class="loading">
      <img src="../../../assets/detail/loading.svg" />
    </div>
    <div v-if="notExist" class="not-exist">
      <img src="../../../assets/detail/doc-deleted.svg" />
      <div>分享关闭了</div>
    </div>
    <div v-if="needExtractionCode" class="code-page">
      <div class="user-info">
        <img class="avatar" :src="docShareInfo.headImageUrl" />
        <div class="info">
          <div class="phone">{{ docShareInfo.nickname }}</div>
          <div class="share">分享视频给你</div>
        </div>
      </div>
      <div class="code-area">
        <input class="code" placeholder="输入4位数密码" v-model="password" />
        <div class="view-button" @click="clickViewButton">查看</div>
      </div>
      <div class="brand-extra" v-if="brandVisible">
        <div class="brand-text">
          <div class="text">让视频沟通更容易、更简单、更流畅</div>
          <img class="close-button" src="../../../assets/component/close.svg" @click="clickCloseButton" />
        </div>
        <div class="line"></div>
        <div class="brand-try">
          <img class="logo" src="../../../assets/component/logo.svg" />
          <div class="try-button" @click="clickTryButton">免费试用</div>
        </div>
      </div>
    </div>
    <div v-if="needWechatLogin" class="wechat-page">
      <div class="dialog" :class="(isInDingDing || isInFeishu) ? 'dialog-short' : ''" ref="wechatDialog" v-if="!inWechat">
        <img v-if="isInDingDing || isInFeishu" class="bg" src="../../../assets/detail/wechat-bg-short.png" />
        <img v-else class="bg" src="../../../assets/detail/wechat-bg.png" />
        <img class="logo" src="../../../assets/component/logo.svg" />
        <div class="user-info">
          <img class="avatar" :src="docShareInfo.headImageUrl" />
          <div class="info">
            <div class="phone">{{ docShareInfo.nickname }}</div>
            <div class="share">分享视频给你</div>
          </div>
        </div>
        <div id="wechat-container" class="qr">
          <vue-qr v-if="isMobile" :text="h5Url" :size="180" :margin="10"></vue-qr>
          <wxlogin v-else :appid="wechatAppId" scope="snsapi_login" :redirect_uri="wechatRedirectUrl" href="https://webfiles.moyincloud.com/www/wechat_custom_h5.css" :state="docId"></wxlogin>
        </div>
        <div class="tip-text">打开微信，扫描图片二维码查看视频</div>
        <div class="save-button" v-if="!isInDingDing && !isInFeishu" @click="clickSaveButton">保存到相册</div>
      </div>
    </div>
  </div>
</template>
<script>
import { addDocViews, getDocDetail, getDocLinkInfo, getDocShareInfo, reportViewRecord, verifyDocShareCode, wechatLogin, updateClick } from '../../../api/doc'
import Brand from '../../../components/Brand'
import { formatDocTime } from '../../../util/time'
import { adjustClass } from '../../../util/dom'
import Player from './Player'
import { isTestEnv } from '../../../util/const'
import { loadPlayerSetting, savePlayerSetting } from '../../../util/storage'
import { clearDocPassword, getDocPassword, getWechatUID, saveDocPassword, setWechatUID } from '../../../util/storage'
import { isInWeixin, isMobile, isInDingDing, isInFeishu } from '../../../util/common'
import VueQr from 'vue-qr'
import wxlogin from 'vue-wxlogin'

export default {
  name: 'MobileDetail',
  components: {
    Player,
    Brand,
    VueQr,
    wxlogin
  },
  data () {
    return {
      testEnv: false,
      docId: '',
      speed: 1,
      currentTime: 0,
      docDuration: 0,
      slideList: [],
      videoWidth: 375,
      videoHeight: 210,
      originVideoWidth: 0,
      originVideoHeight: 0,

      isLoading: false,
      notExist: false,
      title: '',
      avatarImage: '',
      avatar: '',
      author: '',
      time: '',
      docDetail: {},
      docShareInfo: null,
      hasLink: false,
      linkInfo: {},
      userId: null,
      needWechatLogin: false,
      inWechat: false,
      needExtractionCode: false,
      password: '',
      brandVisible: true,
      h5Url: '',
      wechatAppId: '',
      wechatRedirectUrl: '',

      isPlaying: false,
      playEnded: false,
      playInterval: null,
      playMediaFragments: [],
      curFragmentIndex: 0,
      timeWords: [],
      lastWord: null,
      lastWordId: '',

      transcribeOn: false,
      textResult: [],
      hasText: true,

      isScrolling: false,
      isAutoScroll: false,
      isManualScroll: false,
      scrollTimer: null,
      logInterval: null,
      isMobile: false,
      isInDingding: false,
      isInFeishu: false
    }
  },
  mounted () {
    this.testEnv = isTestEnv()
    this.docId = this.$route.query.docId
    this.loadDocShareInfo()
    this.$refs.docContent.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.onWindowResized)
    var setting = loadPlayerSetting()
    if (setting.speed != null) {
      this.speed = setting.speed
    }
    this.userId = getWechatUID()
    if (this.userId == null) {
      if (this.$route.query.uid != null) {
        setWechatUID(this.$route.query.uid)
        this.userId = this.$route.query.uid
      }
    }
    this.isMobile = isMobile()
    this.isInDingding = isInDingDing()
    this.isInFeishu = isInFeishu()
    if (this.testEnv) {
      this.wechatAppId = 'wxd7150da097411a46'
      this.wechatRedirectUrl = 'https://dev.mapi.moyincloud.com/anon/audio/user/h5/doc/loginByWechat'
    } else {
      this.wechatAppId = 'wx4077ea749d1f7141'
      this.wechatRedirectUrl = 'https://mapi.moyincloud.com/anon/audio/user/h5/doc/loginByWechat'
    }
  },
  watch: {
    $route (to) {
      if (to.query.docId !== this.docId) {
        this.docId = to.query.docId
        this.initStatus()
        this.loadDocShareInfo()
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResized)
  },
  methods: {
    initStatus: function () {
      this.currentTime = 0
      this.docDuration = 0
      this.slideList = []
      this.videoWidth = 375
      this.videoHeight = 210
      this.originVideoWidth = 0
      this.originVideoHeight = 0

      this.isLoading = false
      this.notExist = false
      this.title = ''
      this.avatarImage = ''
      this.avatar = ''
      this.author = ''
      this.time = ''
      this.docDetail = {}
      this.docShareInfo = null
      this.userId = null
      this.needWechatLogin = false
      this.needExtractionCode = false
      this.password = ''

      this.isPlaying = false
      if (this.playInterval != null) {
        clearInterval(this.playInterval)
        this.playInterval = null
      }
      this.playMediaFragments = []
      this.curFragmentIndex = 0
      this.timeWords = []
      this.lastWord = null
      this.lastWordId = ''

      this.transcribeOn = false
      this.textResult = []
      this.hasText = true

      this.isScrolling = false
      this.isAutoScroll = false
      this.isManualScroll = false
      if (this.scrollTimer != null) {
        clearTimeout(this.scrollTimer)
        this.scrollTimer = null
      }
      this.$refs.player.init()
    },
    onWindowResized: function () {
      this.onPlayerVideoSize(this.originVideoWidth, this.originVideoHeight)
    },
    onPlayerPlaying: function () {
      this.startInterval()
      this.isPlaying = true
      this.playEnded = false
    },
    onPlayerPause: function () {
      this.stopInterval()
      this.isPlaying = false
    },
    onPlayerEnded: function () {
      this.switchPlayMediaFragmentToNext()
    },
    onPlayerError: function () {
      this.stopInterval()
      this.isPlaying = false
    },
    onPlayerWaiting: function () {
      this.stopInterval()
    },
    onPlayerSeekTime: function (progress) {
      const time = progress 
      this.switchPlayMediaFragmentByTime(time)
      this.currentTime = parseInt(time)
      var destWord = this.findWordByTime(time, true)
      if (destWord) {
        var element = document.getElementById(destWord.id)
        if (!element) {
          return
        }
        var wordId = element.id
        this.handleHighlightTime(element, wordId)
      }
    },
    onPlayerPlayControl: function () {
      if (this.isPlaying) {
        this.$refs.player.pause()
      } else {
        this.$refs.player.play()
      }
    },
    onPlayerSwitchSpeed: function () {
      this.clickSpeedButton()
    },
    onPlayerVideoSize: function (width, height) {
      if (width > 0 && height > 0) {
        this.originVideoWidth = width
        this.originVideoHeight = height
        var maxWidth = this.$refs.mainContent.clientWidth
        var maxHeight = 375
        if (width * maxHeight > maxWidth * height) {
          this.videoWidth = maxWidth
          this.videoHeight = height * this.videoWidth / width
        } else {
          this.videoHeight = maxHeight
          this.videoWidth = width * this.videoHeight / height
        }
      }
    },
    clickShareButton: function () {
      this.$clipboard(window.location.href)
      this.$toasted.success('链接已复制', {
        position: 'bottom-center',
        duration: '2000'
      })
    },
    clickSpeedButton: function () {
      if (this.speed === 1) {
        this.speed = 1.2
      } else if (this.speed === 1.2) {
        this.speed = 1.5
      } else if (this.speed === 1.5) {
        this.speed = 1.7
      } else if (this.speed === 1.7) {
        this.speed = 2
      } else {
        this.speed = 1
      }
      var setting = {
        speed: this.speed
      }
      savePlayerSetting(setting)
    },
    clickBackwardButton: function () {
      this.$refs.player.backward()
    },
    clickPlayButton: function () {
      this.onPlayerPlayControl()
    },
    clickForwardButton: function () {
      this.$refs.player.forward()
    },
    clickTranscribeButton: function () {
      this.transcribeOn = !this.transcribeOn
    },
    clickWord: function (word) {
      var wordId = word.id
      this.switchPlayMediaFragmentByTime(word.audioStart)
      this.handleHighlightTime(document.getElementById(wordId), wordId)
      this.currentTime = word.audioStart
    },
    startInterval: function () {
      if (this.playInterval == null) {
        this.playInterval = setInterval(() => {
          const mediaRealTime = this.$refs.player.getRealCurrentTime()
          var currentTs = 0
          if (mediaRealTime >= this.playMediaFragments[this.curFragmentIndex].mediaEnd) {
            currentTs = this.calcMediaCurrentTime(this.playMediaFragments[this.curFragmentIndex].mediaEnd)
            this.switchPlayMediaFragmentToNext()
          } else {
            currentTs = this.calcMediaCurrentTime(mediaRealTime)
          }
          this.currentTime = currentTs
          this.highlightTime(currentTs)
        }, 100)
      }
      if (this.logInterval == null) {
        this.logInterval = setInterval(() => {
          this.requestReportViewLog()
        }, 3000)
      }
    },
    stopInterval: function () {
      if (this.playInterval != null) {
        clearInterval(this.playInterval)
        this.playInterval = null
      }
      if (this.logInterval != null) {
        clearInterval(this.logInterval)
        this.logInterval = null
      }
    },
    highlightTime: function (timestamp) {
      if (this.timeWords.length > 0) {
        var left = 0
        var right = this.timeWords.length - 1
        var middle = 0
        var destWord = null
        while (left <= right) {
          middle = parseInt((left + right) / 2)
          var middleWord = this.timeWords[middle]
          if (middleWord.audioStart === middleWord.audioEnd && timestamp === middleWord.audioStart) {
            destWord = middleWord
            break
          } else if (timestamp >= middleWord.audioStart && timestamp < middleWord.audioEnd) {
            destWord = middleWord
            break
          } else if (timestamp >= middleWord.audioEnd) {
            left = middle + 1
          } else if (timestamp < middleWord.audioStart) {
            right = middle - 1
          }
        }
        if (destWord != null) {
          this.handleHighlightTime(document.getElementById(destWord.id), destWord.id)
        }
      }
    },
    clearDirtySpan: function (destWord) {
      var selectedElements = document.getElementsByClassName('selected')
      if (selectedElements != null) {
        const elements = []
        for (var i = 0; i < selectedElements.length; i++) {
          var ele = selectedElements[i]
          if (!destWord || ele.id !== destWord.id) {
            elements.push(ele)
          }
        }
        elements.forEach(ele => {
          this.$set(ele, 'className', adjustClass(ele.className, ['selected'], ['normal']))
        })
      }
    },
    handleHighlightTime(destWord, destWordId) {
      this.clearDirtySpan(destWord)
      if (destWord) {
        if (this.lastWord && this.lastWord.id !== destWord.id) {
          this.$set(destWord, 'className', adjustClass(destWord.className, ['normal'], ['selected']))
        } else {
          this.$set(destWord, 'className', adjustClass(destWord.className, ['normal'], ['selected']))
        }
        this.scrollWordToShow(destWord.id)
      }
      this.lastWordId = destWordId
      this.lastWord = destWord
    },
    scrollWordToShow: function (wordId) {
      var wordDom = document.getElementById(wordId)
      if (wordDom == null) {
        return
      }
      var wordTop = wordDom.offsetTop
      var wordHeight = wordDom.offsetHeight

      var scrollTop = this.$refs.docContent.scrollTop
      var offsetHeight = this.$refs.docContent.offsetHeight
      if (wordTop - scrollTop >= 0 && wordTop + wordHeight - scrollTop <= offsetHeight) {
        if (!this.isScrolling) {
          this.isManualScroll = false
        }
      } else {
        if (!this.isManualScroll) {
          if (!this.isScrolling) {
            var destScrollY = wordTop - 50
            this.$refs.docContent.scrollTo({
              top: destScrollY,
              behavior: 'smooth'
            })
            this.isAutoScroll = true
          }
        }
      }
    },
    handleScroll: function () {
      this.isScrolling = true
      if (!this.isAutoScroll) {
        this.isManualScroll = true
      }
      if (this.scrollTimer != null) {
        clearTimeout(this.scrollTimer)
        this.scrollTimer = null
      }
      var $t = this
      this.scrollTimer = setTimeout(function () {
        $t.scrollTimer = null
        $t.handleScrollEnd()
      }, 300)
    },
    handleScrollEnd: function () {
      this.isAutoScroll = false
      this.isScrolling = false
    },
    calcMediaCurrentTime: function (realTime) {
      var currentTs = 0
      for (var i = 0; i < this.curFragmentIndex; i++) {
        currentTs += (this.playMediaFragments[i].mediaEnd - this.playMediaFragments[i].mediaStart)
      }
      currentTs += (realTime - this.playMediaFragments[this.curFragmentIndex].mediaStart)
      return currentTs
    },
    switchPlayMediaFragmentToNext: function () {
      var prevFragment = this.playMediaFragments[this.curFragmentIndex]
      this.curFragmentIndex = this.curFragmentIndex + 1
      if (this.curFragmentIndex >= this.playMediaFragments.length) {
        this.curFragmentIndex = 0
        let curFragment = this.playMediaFragments[0]
        if (curFragment.mediaId !== prevFragment.mediaId) {
          this.$refs.player.updateMediaItem(this.mediaList[curFragment.mediaId - 1])
          this.$nextTick(() => {
            this.$refs.player.setRealCurrentTime(curFragment.mediaStart)
          })
        } else {
          this.$refs.player.setRealCurrentTime(curFragment.mediaStart)
        }
        this.$refs.player.pause()
        this.playEnded = true
      } else {
        let curFragment = this.playMediaFragments[this.curFragmentIndex]
        if (curFragment.mediaId !== prevFragment.mediaId) {
          this.$refs.player.updateMediaItem(this.mediaList[curFragment.mediaId - 1])
          this.$nextTick(() => {
            this.$refs.player.play()
            this.$refs.player.setRealCurrentTime(curFragment.mediaStart)
          })
        } else {
          this.$refs.player.setRealCurrentTime(curFragment.mediaStart)
        }
      }
    },
    switchPlayMediaFragmentByTime: function (timestamp) {
      if (this.playMediaFragments.length === 0) {
        return
      }
      var playing = this.isPlaying
      let left = 0
      let right = this.playMediaFragments.length - 1
      let middle = -1
      while (left <= right) {
        middle = parseInt((left + right) / 2)
        const middleFragment = this.playMediaFragments[middle]
        if (timestamp >= middleFragment.start && timestamp < middleFragment.end) {
          break
        } else if (timestamp >= middleFragment.end) {
          left = middle + 1
        } else if (timestamp < middleFragment.start) {
          right = middle - 1
        }
      }
      var destIndex = middle !== -1 ? middle : 0
      var prevFragment = this.playMediaFragments[this.curFragmentIndex]
      this.curFragmentIndex = destIndex
      var curFragment = this.playMediaFragments[this.curFragmentIndex]
      if (curFragment.mediaId !== prevFragment.mediaId) {
        this.$refs.player.updateMediaItem(this.mediaList[curFragment.mediaId - 1])
        this.$nextTick(() => {
          if (playing) {
            this.$refs.player.play()
          }
          this.$refs.player.setRealCurrentTime(curFragment.mediaStart + timestamp - curFragment.start)
        })
      } else {
        this.$refs.player.setRealCurrentTime(curFragment.mediaStart + timestamp - curFragment.start)
      }
      this.currentTime = timestamp
    },
    findWordByTime(timestamp, allowDot) {
      if (this.timeWords.length === 0) {
        return null
      }
      let left = 0
      let right = this.timeWords.length - 1
      let middle = 0
      while (left <= right) {
        middle = parseInt((left + right) / 2)
        const middleWord = this.timeWords[middle]
        if (middleWord.audioStart === middleWord.audioEnd && middleWord.audioStart === timestamp && allowDot) {
          return middleWord
        } else if (timestamp >= middleWord.audioStart && timestamp < middleWord.audioEnd) {
          return middleWord
        } else if (timestamp >= middleWord.audioEnd) {
          left = middle + 1
        } else if (timestamp < middleWord.audioStart) {
          right = middle - 1
        }
      }
      return null
    },
    loadDocShareInfo: function () {
      const docId = this.docId
      this.serverError = false
      this.isLoading = true
      getDocShareInfo({
        audioDocId: docId
      }).then(res => {
        if (res.data.code === 0) {
          this.docShareInfo = res.data.data
          if (this.docShareInfo.headImageUrl === '') {
            this.docShareInfo.headImageUrl = require('../../../assets/visitor-avatar.png')
          }
          if (this.docShareInfo.needWechatLogin === 1 && this.userId == null) {
            if (isInWeixin()) {
              this.inWechat = true
              if (this.$route.query.code != null) {
                this.checkWechatAuthCode(this.$route.query.code)
              } else {
                this.gotoWechatAuth()
                this.needWechatLogin = true
                this.isLoading = false
              }
            } else {
              this.needWechatLogin = true
              this.isLoading = false
              this.inWechat = false
              this.h5Url = this.buildH5Url()
            }
          } else if (this.docShareInfo.needExtractionCode === 1) {
            var password = getDocPassword(this.docId)
            if (password == null) {
              this.needExtractionCode = true
              this.isLoading = false
            } else {
              this.checkPassword(password, true)
            }
          } else {
            this.loadDocDetail()
          }
        } else {
          console.log(res.data.desc)
          // this.$toasted.error(res.data.desc, {
          //   position: 'bottom-center',
          //   duration: '2000'
          // })
          this.isLoading = false
          this.notExist = true
        }
      }).catch(err => {
        console.log(err)
        this.isLoading = false
        this.$toasted.error('网络错误，请稍后重试', {
          position: 'bottom-center',
          duration: '2000'
        })
      })
    },
    gotoWechatAuth: function () {
      var jumpUrl = window.location.origin + '/jump.html'
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx777fc5968aa96b4b&redirect_uri=' + encodeURIComponent(jumpUrl) + '&response_type=code&scope=snsapi_userinfo&state=' + this.docId + '#wechat_redirect'
    },
    buildH5Url: function () {
      return window.location.origin + "/#/detail?docId=" + this.docId
    },
    checkWechatAuthCode: function (code) {
      this.isLoading = true
      wechatLogin({
        code: code
      }).then(res => {
        if (res.data.code === 0) {
          setWechatUID(res.data.data.user.id)
          this.userId = res.data.data.user.id
          this.needWechatLogin = false
          if (this.docShareInfo.needExtractionCode === 1) {
            var password = getDocPassword(this.docId)
            if (password == null) {
              this.needExtractionCode = true
              this.isLoading = false
            } else {
              this.checkPassword(password, true)
            }
          } else {
            this.loadDocDetail()
          }
        } else if (res.data.code === 5012) {
          this.gotoWechatAuth()
          this.needWechatLogin = true
          this.isLoading = false
        } else {
          console.log(res.data.desc)
          this.$toasted.error(res.data.desc, {
            position: 'bottom-center',
            duration: '2000'
          })
          this.needWechatLogin = true
          this.isLoading = false
        }
      }).catch(err => {
        console.log(err)
        this.isLoading = false
        this.$toasted.error('网络错误，请稍后重试', {
          position: 'bottom-center',
          duration: '2000'
        })
      })
    },
    checkPassword: function (password, auto) {
      verifyDocShareCode({
        audioDocId: this.docId,
        code: password
      }).then(res => {
        if (res.data.code === 0) {
          saveDocPassword(this.docId, password)
          this.loadDocDetail()
          this.needExtractionCode = false
          this.isLoading = false
        } else {
          clearDocPassword(this.docId)
          this.needExtractionCode = true
          this.isLoading = false
          if (!auto) {
            this.$toasted.error('验证失败，请重新输入', {
              position: 'bottom-center',
              duration: '2000'
            })
          }
        }
      }).catch(err => {
        console.log(err)
        this.isLoading = false
        this.$toasted.error('网络错误，请稍后重试', {
          position: 'bottom-center',
          duration: '2000'
        })
      })
    },
    clickViewButton: function () {
      if (this.password === '') {
        this.$toasted.error('密码为空', {
          position: 'bottom-center',
          duration: '2000'
        })
        return
      }
      this.checkPassword(this.password, false)
    },
    loadDocDetail: function () {
      const docId = this.docId
      this.serverError = false
      this.isLoading = true

      getDocDetail({
        audioDocId: docId
      }).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          this.docDetail = data
          this.dealResultMeta(data)
          this.dealTextResult(data)
          this.dealMediaFragment(data)
          addDocViews({
            audioDocId: docId
          })
          this.loadDocLinkInfo()
        } else if (res.data.code === 3004) {
          this.notExist = true
        } else {
          console.log(res.data.desc)
          this.$toasted.error(res.data.desc, {
            position: 'bottom-center',
            duration: '2000'
          })
        }
        this.isLoading = false
      }).catch(err => {
        console.log(err)
        this.isLoading = false
        this.$toasted.error('网络错误，请稍后重试', {
          position: 'bottom-center',
          duration: '2000'
        })
      })
    },
    loadDocLinkInfo: function () {
      getDocLinkInfo({
        docId: this.docId
      }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data == null) {
            this.hasLink = false
          } else {
            this.hasLink = true
            let style = JSON.parse(res.data.data.style)
            res.data.data.textColor = style.textColor ? style.textColor : '#FFFFFFFF'
            res.data.data.cornerSize = style.cornerSize ? style.cornerSize : 4
            res.data.data.buttonColor = style.buttonColor ? style.buttonColor : '#3E7FFFFF'
            this.linkInfo = res.data.data
          }
        }
      })
    },
    dealResultMeta: function (docInfo) {
      this.title = docInfo.title === '' ? '未命名文档' : docInfo.title
      document.title = this.title
      this.author = (docInfo.userInfo && docInfo.userInfo.nickname) || '魔音用户'
      this.avatar = this.buildAvatar(this.author)
      this.avatarImage = (docInfo.userInfo && docInfo.userInfo.imageUrl) || ''
      this.time = formatDocTime(docInfo.updateTime)
    },
    buildAvatar: function (author) {
      if (author != null && author.length > 0) {
        return author.substr(0, 1)
      } else {
        return '魔'
      }
    },
    dealTextResult: function (docInfo) {
      if (docInfo.blockList != null && docInfo.blockList.length > 0) {
        var currentTs = 0
        var hasText = false
        docInfo.blockList.forEach((block, blockIndex) => {
          var textBlock = {}
          if (block.type === 'wordList') {
            textBlock.type = 'wordList'
            textBlock.words = []
            block.wordList.forEach((word, wordIndex) => {
              if (word.status !== 'delete' && word.del === 0) {
                word.id = `word-${blockIndex}-${wordIndex}`
                if (word.style != null && word.style.trim() !== '') {
                  try {
                    const { tags, styles } = JSON.parse(word.style)
                    if (tags != null) {
                      tags.forEach(item => {
                        if (item === 'strong') {
                          word.className = `${word.className} bold`
                        }
                        if (item === 'em') {
                          word.className = `${word.className} italic`
                        }
                        if (item === 'u') {
                          word.className = `${word.className} strike`
                        }
                      })
                    }
                    if (styles != null) {
                      word.style = styles
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }
                word.audioStart = currentTs
                word.audioEnd = currentTs + word.realAudioEnd - word.realAudioStart
                if (word.type === 'silence') {
                  word.word = ''
                } else {
                  hasText = true
                }
                currentTs = word.audioEnd
                textBlock.words.push(word)
                this.timeWords.push(word)
              }
            })
          } else if (block.type === 'h1') {
            textBlock.type = 'h1'
            textBlock.title = block.title
          }
          this.textResult.push(textBlock)
        })
        this.hasText = hasText
      }
    },
    dealMediaFragment: function (docInfo) {
      this.mediaList = docInfo.mediaList
      var fragments = []
      var curFragment = null
      function toFragment (word) {
        return {
          mediaId: word.mediaId,
          mediaStart: word.realAudioStart,
          mediaEnd: word.realAudioEnd,
          start: word.audioStart,
          end: word.audioEnd
        }
      }
      function switchFragment (curFragment, wordObj, fragments) {
        if (curFragment == null) {
          curFragment = toFragment(wordObj)
        } else {
          if (curFragment.mediaId !== wordObj.mediaId) {
            fragments.push(curFragment)
            curFragment = toFragment(wordObj)
          } else {
            if (wordObj.realAudioStart === curFragment.mediaEnd) {
              curFragment.mediaEnd = wordObj.realAudioEnd
              curFragment.end = wordObj.audioEnd
            } else {
              fragments.push(curFragment)
              curFragment = toFragment(wordObj)
            }
          }
        }
        return curFragment
      }
      for (var i = 0; i < this.timeWords.length; i++) {
        var wordObj = this.timeWords[i]
        if (!(wordObj.audioStart === wordObj.audioEnd)) {
          curFragment = switchFragment(curFragment, wordObj, fragments)
        }
      }
      if (curFragment != null) {
        fragments.push(curFragment)
        this.docDuration = curFragment.end
      }
      this.playMediaFragments = fragments
      this.curFragmentIndex = 0
      if (fragments.length === 0) {
        this.playMediaFragments.push({
          mediaId: 1,
          mediaStart: 0,
          mediaEnd: docInfo.duration,
          start: 0,
          end: docInfo.duration
        })
        this.docDuration = docInfo.duration
      }
      if (this.playMediaFragments.length > 0) {
        this.$refs.player.updateMediaItem(this.mediaList[this.playMediaFragments[0].mediaId - 1])
        this.$refs.player.setRealCurrentTime(this.playMediaFragments[0].mediaStart)
      }
    },
    clickCloseButton: function () {
      this.brandVisible = false
    },
    clickTryButton: function () {
      window.location.href = 'https://www.moyincloud.com'
    },
    clickSaveButton: function () {
      var domtoimage = require('dom-to-image')
      domtoimage.toBlob(this.$refs.wechatDialog).then(blob => {
        var url = URL.createObjectURL(blob)
        var link = document.createElement('a')
        link.download = '魔音视频_' + this.docId + '.png'
        link.href = url
        link.click()
      })
    },
    requestReportViewLog: function () {
      var param = {
        version: this.docDetail.currentVersion,
        speed: this.speed,
        time: parseInt(this.currentTime),
        audioDocId: this.docId,
        interval: 3
      }
      if (this.userId != null) {
        param.userId = this.userId
      }
      reportViewRecord(param).then(res => {
        if (res.data.code !== 0) {
          console.log(res.data.desc)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    linkClick (time) {
      updateClick({docId: this.docId, userId: this.userId, clickTime: time})
    }
  }
}
</script>
<style scoped lang="scss">
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  -webkit-tap-highlight-color: transparent;
  .title {
    margin: 16px 16px 12px 16px;
    font-size: 20px;
    font-weight: 500;
    color: rgba(51,51,51,1);
    position: relative;
    .top-test-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 20px;
        height: 20px;
    }
  }
  .center-content {
    flex-grow: 1;
    height: 0;
    position: relative;
    .info {
      margin: 0 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .avatar {
        width: 38px;
        height: 38px;
        background: rgba(222, 40, 152, 1);
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
      }
      .avatar-img {
        width: 38px;
        height: 38px;
        border-radius: 19px;
      }
      .meta {
        margin-left: 10px;
        margin-right: 10px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .name {
          font-size: 13px;
          font-weight: 500;
          color: rgba(34,34,34,1);
        }
        .time {
          margin-top: 2px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
      }
      .share-button {
        width: 84px;
        height: 32px;
        background: url(../../../assets/detail/share-button-normal.svg);
        background-size: 84px 32px;
        background-repeat: no-repeat;
        cursor: pointer;
        &:hover {
          background: url(../../../assets/detail/share-button-hover.svg);
        }
        &:active {
          background: url(../../../assets/detail/share-button-hover.svg);
        }
      }
    }
    .brand {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
    }
    .doc-content {
      overflow-y: scroll;
      position: relative;
      height: calc(100% - 16px);
      padding: 0 16px 16px 16px;
      user-select: text;
      & h1 {
        font-size: 18px;
        font-weight: 500;
        color: #4A4A4A;
        cursor: default;
        margin-top: 0;
        margin-bottom: 10px;
      }
      .section-top {
        height: 12px;
      }
      .section {
        font-size: 14px;
        font-weight: normal;
        color: rgba(34,34,34,1);
        line-height: 25px;
        margin-bottom: 10px;
        text-align: left;
        & span {
          margin: 4px 0px 0px 0px;
          color: rgba(49, 49, 49, 1);
          word-break: break-word;
          cursor: pointer;
        }
        .strike {
          /* color: #b2b2b2; */
          /* text-decoration: line-through; */
          text-decoration: underline;
        }
        .bold {
          font-weight: bold;
        }
        .italic {
          font-style: italic;
        }
        .selected {
          background: rgba(0, 98, 255, 1);
          color: #ffffff;
          /*animation: bomb 0.4s;*/
        }
      }
      .no-text {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & img {
          width: 120px;
          height: 120px;
        }
        & div {
          margin-top: 12px;
          margin-bottom: 50px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
      }
    }
  }
  .toolbar {
    height: 56px;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    background: rgba(247,247,247,1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 100;
    & button {
      width: 56px;
      height: 56px;
      cursor: pointer;
      & img {
        width: 56px;
        height: 56px;
      }
    }
  }
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    background: rgba(255,255,255,1);
    z-index: 10000;
    & img {
      width: 43px;
      height: 43px;
      -webkit-animation: changeright 1s linear infinite;
    }
  }
  @-webkit-keyframes changeright {
    0% {
      -webkit-transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  .not-exist {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(247,247,247,1);
    z-index: 10000;
    & img {
      width: 120px;
      height: 120px;
    }
    & div {
      margin-top: 12px;
      font-size: 14px;
      font-weight: normal;
      color: rgba(102,102,102,1);
    }
  }
  .code-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    z-index: 1000;
    .user-info {
      height: 90px;
      width: 100%;
      background: rgba(246,247,251,1);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .avatar {
        width: 34px;
        height: 34px;
        border-radius: 17px;
      }
      .info {
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        .phone {
          font-size: 12px;
          font-weight: normal;
          color: rgba(43,43,43,1);
        }
        .share {
          margin-top: 6px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(43,43,43,1);
        }
      }
    }
    .code-area {
      position: relative;
      top: -8px;
      width: 100%;
      background: rgba(255,255,255,1);
      border-radius: 10px 10px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .code {
        margin-top: 59px;
        width: 279px;
        height: 45px;
        line-height: 45px;
        font-size: 15px;
        font-weight: normal;
        color: rgba(102,102,102,1);
        text-align: center;
        outline: none;
        border: 0;
        background: rgba(246,247,251,1);
        border-radius: 27px;
      }
      .view-button {
        margin-top: 25px;
        margin-bottom: 50px;
        width: 279px;
        height: 45px;
        background: rgba(0,112,255,1);
        border: 1px solid rgba(151,151,151,1);
        border-radius: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        color: rgba(255,255,255,1);
        cursor: pointer;
      }
    }
    .brand-extra {
      width: 100%;
      background: rgba(235,241,255,1);
      display: flex;
      flex-direction: column;
      .brand-text {
        height: 46px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        .text {
          font-size: 14px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
        .close-button {
          width: 16px;
          height: 16px;
        }
      }
      .line {
        height: 1px;
        background: rgba(216,216,216,1);
      }
      .brand-try {
        height: 74px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        .logo {
          width: 100px;
          height: 23px;
        }
        .try-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 84px;
          height: 32px;
          background: rgba(255,255,255,1);
          border: 1px solid rgba(0,112,255,1);
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(0,112,255,1);
          cursor: pointer;
        }
      }
    }
  }
  .wechat-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    z-index: 1000;
    .dialog {
      position: relative;
      width: 342px;
      height: 423px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 342px;
        height: 423px;
      }
      .logo {
        position: relative;
        align-self: flex-start;
        margin-top: 12px;
        margin-left: 12px;
        width: 90px;
        height: 21px;
      }
      .user-info {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        .avatar {
          width: 34px;
          height: 34px;
          border-radius: 17px;
        }
        .info {
          margin-left: 6px;
          display: flex;
          flex-direction: column;
          .phone {
            font-size: 12px;
            font-weight: normal;
            color: rgba(43,43,43,1);
          }
          .share {
            font-size: 14px;
            font-weight: 500;
            color: rgba(43,43,43,1);
          }
        }
      }
      .qr {
        position: relative;
        margin-top: 14px;
        width: 180px;
        height: 180px;
        border: 8px solid #BBCFFF;
        background: white;
        overflow: hidden;
      }
      .tip-text {
        position: relative;
        margin-top: 22px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(255,255,255,0.9);
      }
      .save-button {
        position: relative;
        margin-top: 43px;
        width: 121px;
        height: 41px;
        background: rgba(238,243,255,1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: normal;
        color: rgba(51,51,51,1);
        cursor: pointer;
      }
    }
    .dialog-short {
      height: 345px;
      .bg {
        height: 345px;
      }
    }
  }
}
</style>
