<template>
  <div class="main">
    <div class="header" v-if="headerVisible">
      <div class="header-inner">
        <img class="logo" src="../../../assets/web/logo.svg" />
        <div @click="clickTopCloseButton" class="close-button"></div>
        <img v-if="testEnv" class="top-test-icon" src="../../../assets/icon_test.svg" />
      </div>
    </div>
    <div class="main-content" v-show="!notExist">
      <div class="player-content">
        <player
          ref="player"
          :current="currentTime"
          :duration="docDuration"
          :slideList="slideList"
          :transcribeOn="transcribeOn"
          :videoWidth="videoWidth"
          :videoHeight="videoHeight"
          :outLoading="isLoading"
          :hasLink="hasLink"
          :linkInfo="linkInfo"
          :playEnded="playEnded"
          @playing="onPlayerPlaying"
          @pause="onPlayerPause"
          @ended="onPlayerEnded"
          @error="onPlayerError"
          @waiting="onPlayerWaiting"
          @seekTime="onPlayerSeekTime"
          @playControl="onPlayerPlayControl"
          @transcribeControl="onPlayerTranscribeControl"
          @videoSize="onPlayerVideoSize"
          @linkClick='linkClick'
          />
        <div class="title">{{ title }}</div>
        <div class="info">
          <img v-if="avatarImage != null && avatarImage !== ''" class="avatar-img" :src="avatarImage" />
          <div class="avatar" v-else>{{ avatar }}</div>
          <div class="meta">
            <div class="name">{{ author }}</div>
            <div class="time">{{ time }}</div>
          </div>
          <div class="share-button" @click="clickShareButton">
            <img src="../../../assets/web/share.svg" />
            <div>分享</div>
          </div>
        </div>
      </div>
      <div class="text-content" v-show="transcribeOn" :style="{ height: videoHeight > 0 ? (videoHeight - 2) + 'px' : '' }">
        <div class="title-line">
          <img class="icon" src="../../../assets/web/text.svg" />
          <div class="title">文字稿</div>
        </div>
        <div class="line"></div>
        <img @click="clickCloseButton" class="close-button" src="../../../assets/web/close.svg" />
        <div class="doc-content" ref="docContent">
          <div class="section-top"></div>
          <template v-for="(block, index1) in textResult">
            <div v-if="block.type == 'wordList'" class="section" :key="index1">
              <span
                v-for="word in block.words"
                :key="word.id"
                @click="clickWord(word)"
                :id="word.id"
                :data-audio-start="word.audioStart"
                :data-audio-end="word.audioEnd"
                :class="word.className"
                :style="word.style"
                >{{ word.word }}</span
              >
            </div>
            <h1 v-else-if="block.type == 'h1'" :key="index1">{{ block.title }}</h1>
          </template>
          <div v-if="!hasText" class="no-text">
            <img src="../../../assets/detail/doc-notext.svg" />
            <div>文字稿为空</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="loading" :style="{ top: headerVisible ? '56px' : '0px' }">
      <img src="../../../assets/web/loading.svg" />
    </div>
    <div v-if="notExist" class="not-exist" :style="{ top: headerVisible ? '56px' : '0px' }">
      <img src="../../../assets/detail/doc-deleted.svg" />
      <div>分享关闭了</div>
    </div>
    <div v-if="needExtractionCode" class="code-page" :style="{ top: headerVisible ? '56px' : '0px' }">
      <div class="dialog">
        <img class="logo" src="../../../assets/component/logo.svg" />
        <div class="header">
          <img class="avatar" :src="docShareInfo.headImageUrl" />
          <div class="info">
            <div class="phone">{{ docShareInfo.nickname }}</div>
            <div class="share">分享视频给你</div>
          </div>
        </div>
        <div class="code-area">
          <div class="title">请输入密码</div>
          <div class="input-line">
            <input class="code" placeholder="输入4位数密码" v-model="password" />
            <div class="view-button" @click="clickViewButton">查看</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="needWechatLogin" class="wechat-page" :style="{ top: headerVisible ? '56px' : '0px' }">
      <div class="dialog">
        <img class="bg" src="../../../assets/web/wechat-bg.png" />
        <img class="logo" src="../../../assets/component/logo.svg" />
        <div class="user-info">
          <img class="avatar" :src="docShareInfo.headImageUrl" />
          <div class="info">
            <div class="phone">{{ docShareInfo.nickname }}</div>
            <div class="share">分享视频给你</div>
          </div>
        </div>
        <div id="wechat-container" class="qr">
          <wxlogin :appid="wechatAppId" scope="snsapi_login" :redirect_uri="wechatRedirectUrl" href="https://webfiles.moyincloud.com/www/wechat_custom.css" :state="docId"></wxlogin>
        </div>
        <div class="tip-text">打开微信，扫描二维码查看视频</div>
        <img class="tip-image" src="../../../assets/web/wechat-login.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { addDocViews, getDocDetail, getDocLinkInfo, getDocShareInfo, reportViewRecord, verifyDocShareCode, updateClick } from '../../../api/doc'
import { formatDocTime } from '../../../util/time'
import { adjustClass } from '../../../util/dom'
import Player from './Player.vue'
import { isTestEnv } from '../../../util/const'
import { clearDocPassword, getDocPassword, getWechatUID, saveDocPassword, setWechatUID } from '../../../util/storage'
import wxlogin from 'vue-wxlogin'
// import { loadWebIndexSetting, saveWebIndexSetting } from '../../../util/storage'
// import { getUid, getToken, getSalt, removeUserInfo } from './storage'

export default {
  name: 'WebDetail',
  components: {
    Player,
    wxlogin
  },
  data () {
    return {
      testEnv: false,
      docId: '',
      headerVisible: true,
      currentTime: 0,
      docDuration: 0,
      slideList: [],
      videoWidth: 1000,
      videoHeight: 576,

      isLoading: false,
      notExist: false,
      title: '',
      avatarImage: '',
      avatar: '',
      author: '',
      time: '',
      docDetail: {},
      docShareInfo: null,
      hasLink: false,
      linkInfo: {},
      userId: null,
      needWechatLogin: false,
      needExtractionCode: false,
      password: '',
      wechatAppId: '',
      wechatRedirectUrl: '',

      isPlaying: false,
      playEnded: false,
      playInterval: null,
      playMediaFragments: [],
      curFragmentIndex: 0,
      timeWords: [],
      lastWord: null,
      lastWordId: '',

      transcribeOn: false,
      textResult: [],
      hasText: true,

      isScrolling: false,
      isAutoScroll: false,
      isManualScroll: false,
      scrollTimer: null,
      logInterval: null
    }
  },
  mounted () {
    this.testEnv = isTestEnv()
    this.docId = this.$route.query.docId
    this.loadDocShareInfo()
    this.$refs.docContent.addEventListener('scroll', this.handleScroll)
    this.userId = getWechatUID()
    if (this.userId == null) {
      if (this.$route.query.uid != null) {
        setWechatUID(this.$route.query.uid)
        this.userId = this.$route.query.uid
      }
    }
    if (this.testEnv) {
      this.wechatAppId = 'wxd7150da097411a46'
      this.wechatRedirectUrl = 'https://dev.mapi.moyincloud.com/anon/audio/user/h5/doc/loginByWechat'
    } else {
      this.wechatAppId = 'wx4077ea749d1f7141'
      this.wechatRedirectUrl = 'https://mapi.moyincloud.com/anon/audio/user/h5/doc/loginByWechat'
    }
  },
  watch: {
    $route (to) {
      if (to.query.docId !== this.docId) {
        this.docId = to.query.docId
        this.initStatus()
        this.loadDocShareInfo()
      }
    }
  },
  methods: {
    initStatus: function () {
      this.currentTime = 0
      this.docDuration = 0
      this.slideList = []
      this.videoWidth = 1000
      this.videoHeight = 576
      this.title = ''
      this.avatarImage = ''
      this.avatar = ''
      this.author = ''
      this.time = ''
      this.notExist = false
      this.docDetail = {}
      this.docShareInfo = null
      this.userId = null
      this.needWechatLogin = false
      this.needExtractionCode = false
      this.password = ''
      this.isPlaying = false
      if (this.playInterval != null) {
        clearInterval(this.playInterval)
        this.playInterval = null
      }
      this.playMediaFragments = []
      this.curFragmentIndex = 0
      this.timeWords = []
      this.lastWord = null
      this.lastWordId = ''
      this.transcribeOn = false
      this.textResult = []
      this.hasText = true

      this.isScrolling = false
      this.isAutoScroll = false
      this.isManualScroll = false
      if (this.scrollTimer != null) {
        clearTimeout(this.scrollTimer)
        this.scrollTimer = null
      }
      this.$refs.player.init()
    },
    clickTopCloseButton: function () {
      console.log('clickTopCoseButton')
      this.headerVisible = false
      // var setting = {
      //   headerVisible: false
      // }
      // saveWebIndexSetting(setting)
    },
    clickDownloadButton: function () {
      window.open('https://www.moyincloud.com')
    },
    clickCloseButton: function () {
      this.transcribeOn = false
    },
    onPlayerPlaying: function () {
      this.startInterval()
      this.isPlaying = true
      this.playEnded = false
    },
    onPlayerPause: function () {
      this.stopInterval()
      this.isPlaying = false
    },
    onPlayerEnded: function () {
      this.switchPlayMediaFragmentToNext()
    },
    onPlayerError: function () {
      this.stopInterval()
      this.isPlaying = false
    },
    onPlayerWaiting: function () {
      this.stopInterval()
    },
    onPlayerSeekTime: function (progress) {
      const time = progress 
      this.switchPlayMediaFragmentByTime(time)
      this.currentTime = parseInt(time)
      var destWord = this.findWordByTime(time, true)
      if (destWord) {
        var element = document.getElementById(destWord.id)
        if (!element) {
          return
        }
        var wordId = element.id
        this.handleHighlightTime(element, wordId)
      }
    },
    onPlayerPlayControl: function () {
      if (this.isPlaying) {
        this.$refs.player.pause()
      } else {
        this.$refs.player.play()
      }
    },
    onPlayerTranscribeControl: function () {
      this.transcribeOn = !this.transcribeOn
    },
    onPlayerVideoSize: function (width, height) {
      if (width > 0 && height > 0) {
        if (width * 576 > 1000 * height) {
          this.videoWidth = 1000
          this.videoHeight = height * this.videoWidth / width
        } else {
          this.videoHeight = 576
          this.videoWidth = width * this.videoHeight / height
        }
      }
    },
    clickShareButton: function () {
      this.$clipboard(window.location.href)
      this.$toasted.success('链接已复制', {
        position: 'bottom-center',
        duration: '2000'
      })
    },
    clickWord: function (word) {
      var wordId = word.id
      this.switchPlayMediaFragmentByTime(word.audioStart)
      this.handleHighlightTime(document.getElementById(wordId), wordId)
      this.currentTime = word.audioStart
    },
    startInterval: function () {
      if (this.playInterval == null) {
        this.playInterval = setInterval(() => {
          const mediaRealTime = this.$refs.player.getRealCurrentTime()
          var currentTs = 0
          if (mediaRealTime >= this.playMediaFragments[this.curFragmentIndex].mediaEnd) {
            currentTs = this.calcMediaCurrentTime(this.playMediaFragments[this.curFragmentIndex].mediaEnd)
            this.switchPlayMediaFragmentToNext()
          } else {
            currentTs = this.calcMediaCurrentTime(mediaRealTime)
          }
          this.currentTime = currentTs
          this.highlightTime(currentTs)
        }, 100)
      }
      if (this.logInterval == null) {
        this.logInterval = setInterval(() => {
          this.requestReportViewLog()
        }, 3000)
      }
    },
    stopInterval: function () {
      if (this.playInterval != null) {
        clearInterval(this.playInterval)
        this.playInterval = null
      }
      if (this.logInterval != null) {
        clearInterval(this.logInterval)
        this.logInterval = null
      }
    },
    highlightTime: function (timestamp) {
      if (this.timeWords.length > 0) {
        var left = 0
        var right = this.timeWords.length - 1
        var middle = 0
        var destWord = null
        while (left <= right) {
          middle = parseInt((left + right) / 2)
          var middleWord = this.timeWords[middle]
          if (middleWord.audioStart === middleWord.audioEnd && timestamp === middleWord.audioStart) {
            destWord = middleWord
            break
          } else if (timestamp >= middleWord.audioStart && timestamp < middleWord.audioEnd) {
            destWord = middleWord
            break
          } else if (timestamp >= middleWord.audioEnd) {
            left = middle + 1
          } else if (timestamp < middleWord.audioStart) {
            right = middle - 1
          }
        }
        if (destWord != null) {
          this.handleHighlightTime(document.getElementById(destWord.id), destWord.id)
        }
      }
    },
    clearDirtySpan: function (destWord) {
      var selectedElements = document.getElementsByClassName('selected')
      if (selectedElements != null) {
        const elements = []
        for (var i = 0; i < selectedElements.length; i++) {
          var ele = selectedElements[i]
          if (!destWord || ele.id !== destWord.id) {
            elements.push(ele)
          }
        }
        elements.forEach(ele => {
          this.$set(ele, 'className', adjustClass(ele.className, ['selected'], ['normal']))
        })
      }
    },
    handleHighlightTime(destWord, destWordId) {
      this.clearDirtySpan(destWord)
      if (destWord) {
        if (this.lastWord && this.lastWord.id !== destWord.id) {
          this.$set(destWord, 'className', adjustClass(destWord.className, ['normal'], ['selected']))
        } else {
          this.$set(destWord, 'className', adjustClass(destWord.className, ['normal'], ['selected']))
        }
        this.scrollWordToShow(destWord.id)
      }
      this.lastWordId = destWordId
      this.lastWord = destWord
    },
    scrollWordToShow: function (wordId) {
      var wordDom = document.getElementById(wordId)
      if (wordDom == null) {
        return
      }
      var wordTop = wordDom.offsetTop
      var wordHeight = wordDom.offsetHeight

      var scrollTop = this.$refs.docContent.scrollTop
      var offsetHeight = this.$refs.docContent.offsetHeight
      if (wordTop - scrollTop >= 0 && wordTop + wordHeight - scrollTop <= offsetHeight) {
        if (!this.isScrolling) {
          this.isManualScroll = false
        }
      } else {
        if (!this.isManualScroll) {
          if (!this.isScrolling) {
            var destScrollY = wordTop - 50
            this.$refs.docContent.scrollTo({
              top: destScrollY,
              behavior: 'smooth'
            })
            this.isAutoScroll = true
          }
        }
      }
    },
    handleScroll: function () {
      this.isScrolling = true
      if (!this.isAutoScroll) {
        this.isManualScroll = true
      }
      if (this.scrollTimer != null) {
        clearTimeout(this.scrollTimer)
        this.scrollTimer = null
      }
      var $t = this
      this.scrollTimer = setTimeout(function () {
        $t.scrollTimer = null
        $t.handleScrollEnd()
      }, 300)
    },
    handleScrollEnd: function () {
      this.isAutoScroll = false
      this.isScrolling = false
    },
    calcMediaCurrentTime: function (realTime) {
      var currentTs = 0
      for (var i = 0; i < this.curFragmentIndex; i++) {
        currentTs += (this.playMediaFragments[i].mediaEnd - this.playMediaFragments[i].mediaStart)
      }
      currentTs += (realTime - this.playMediaFragments[this.curFragmentIndex].mediaStart)
      return currentTs
    },
    switchPlayMediaFragmentToNext: function () {
      var prevFragment = this.playMediaFragments[this.curFragmentIndex]
      this.curFragmentIndex = this.curFragmentIndex + 1
      if (this.curFragmentIndex >= this.playMediaFragments.length) {
        this.curFragmentIndex = 0
        let curFragment = this.playMediaFragments[0]
        if (curFragment.mediaId !== prevFragment.mediaId) {
          this.$refs.player.updateMediaItem(this.mediaList[curFragment.mediaId - 1])
          this.$nextTick(() => {
            this.$refs.player.setRealCurrentTime(curFragment.mediaStart)
          })
        } else {
          this.$refs.player.setRealCurrentTime(curFragment.mediaStart)
        }
        this.$refs.player.pause()
        this.playEnded = true
      } else {
        let curFragment = this.playMediaFragments[this.curFragmentIndex]
        if (curFragment.mediaId !== prevFragment.mediaId) {
          this.$refs.player.updateMediaItem(this.mediaList[curFragment.mediaId - 1])
          this.$nextTick(() => {
            this.$refs.player.play()
            this.$refs.player.setRealCurrentTime(curFragment.mediaStart)
          })
        } else {
          this.$refs.player.setRealCurrentTime(curFragment.mediaStart)
        }
      }
    },
    switchPlayMediaFragmentByTime: function (timestamp) {
      if (this.playMediaFragments.length === 0) {
        return
      }
      var playing = this.isPlaying
      let left = 0
      let right = this.playMediaFragments.length - 1
      let middle = -1
      while (left <= right) {
        middle = parseInt((left + right) / 2)
        const middleFragment = this.playMediaFragments[middle]
        if (timestamp >= middleFragment.start && timestamp < middleFragment.end) {
          break
        } else if (timestamp >= middleFragment.end) {
          left = middle + 1
        } else if (timestamp < middleFragment.start) {
          right = middle - 1
        }
      }
      var destIndex = middle !== -1 ? middle : 0
      var prevFragment = this.playMediaFragments[this.curFragmentIndex]
      this.curFragmentIndex = destIndex
      var curFragment = this.playMediaFragments[this.curFragmentIndex]
      if (curFragment.mediaId !== prevFragment.mediaId) {
        this.$refs.player.updateMediaItem(this.mediaList[curFragment.mediaId - 1])
        this.$nextTick(() => {
          if (playing) {
            this.$refs.player.play()
          }
          this.$refs.player.setRealCurrentTime(curFragment.mediaStart + timestamp - curFragment.start)
        })
      } else {
        this.$refs.player.setRealCurrentTime(curFragment.mediaStart + timestamp - curFragment.start)
      }
      this.currentTime = timestamp
    },
    findWordByTime(timestamp, allowDot) {
      if (this.timeWords.length === 0) {
        return null
      }
      let left = 0
      let right = this.timeWords.length - 1
      let middle = 0
      while (left <= right) {
        middle = parseInt((left + right) / 2)
        const middleWord = this.timeWords[middle]
        if (middleWord.audioStart === middleWord.audioEnd && middleWord.audioStart === timestamp && allowDot) {
          return middleWord
        } else if (timestamp >= middleWord.audioStart && timestamp < middleWord.audioEnd) {
          return middleWord
        } else if (timestamp >= middleWord.audioEnd) {
          left = middle + 1
        } else if (timestamp < middleWord.audioStart) {
          right = middle - 1
        }
      }
      return null
    },
    loadDocShareInfo: function () {
      const docId = this.docId
      this.serverError = false
      this.isLoading = true
      getDocShareInfo({
        audioDocId: docId
      }).then(res => {
        this.isLoading = false
        if (res.data.code === 0) {
          this.docShareInfo = res.data.data
          if (this.docShareInfo.headImageUrl === '') {
            this.docShareInfo.headImageUrl = require('../../../assets/visitor-avatar.png')
          }
          if (this.docShareInfo.needWechatLogin === 1 && this.userId == null) {
            this.needWechatLogin = true
          } else if (this.docShareInfo.needExtractionCode === 1) {
            var password = getDocPassword(this.docId)
            if (password == null) {
              this.needExtractionCode = true
            } else {
              this.checkPassword(password, true)
            }
          } else {
            this.loadDocDetail()
          }
        } else {
          console.log(res.data.desc)
          // this.$toasted.error(res.data.desc, {
          //   position: 'bottom-center',
          //   duration: '2000'
          // })
          this.notExist = true
        }
        this.isLoading = false
      }).catch(err => {
        console.log(err)
        this.isLoading = false
        this.$toasted.error('网络错误，请稍后重试', {
          position: 'bottom-center',
          duration: '2000'
        })
      })
    },
    checkPassword: function (password, auto) {
      verifyDocShareCode({
        audioDocId: this.docId,
        code: password
      }).then(res => {
        if (res.data.code === 0) {
          saveDocPassword(this.docId, password)
          this.loadDocDetail()
          this.needExtractionCode = false
        } else {
          clearDocPassword(this.docId)
          this.needExtractionCode = true
          if (!auto) {
            this.$toasted.error('验证失败，请重新输入', {
              position: 'bottom-center',
              duration: '2000'
            })
          }
        }
      }).catch(err => {
        console.log(err)
        this.$toasted.error('网络错误，请稍后重试', {
          position: 'bottom-center',
          duration: '2000'
        })
      })
    },
    clickViewButton: function () {
      if (this.password === '') {
        this.$toasted.error('密码为空', {
          position: 'bottom-center',
          duration: '2000'
        })
        return
      }
      this.checkPassword(this.password, false)
    },
    loadDocDetail: function () {
      const docId = this.docId
      this.serverError = false
      this.isLoading = true

      getDocDetail({
        audioDocId: docId
      }).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          this.docDetail = data
          this.dealResultMeta(data)
          this.dealTextResult(data)
          this.dealMediaFragment(data)
          addDocViews({
            audioDocId: docId
          })
          this.loadDocLinkInfo()
        } else if (res.data.code === 3004) {
          this.notExist = true
        } else {
          console.log(res.data.desc)
          this.$toasted.error(res.data.desc, {
            position: 'bottom-center',
            duration: '2000'
          })
        }
        this.isLoading = false
      }).catch(err => {
        console.log(err)
        this.isLoading = false
        this.$toasted.error('网络错误，请稍后重试', {
          position: 'bottom-center',
          duration: '2000'
        })
      })
    },
    loadDocLinkInfo: function () {
      getDocLinkInfo({
        docId: this.docId
      }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data == null) {
            this.hasLink = false
          } else {
            this.hasLink = true
            let style = JSON.parse(res.data.data.style)
            res.data.data.textColor = style.textColor ? style.textColor : '#FFFFFFFF'
            res.data.data.cornerSize = style.cornerSize ? style.cornerSize : 4
            res.data.data.buttonColor = style.buttonColor ? style.buttonColor : '#3E7FFFFF'
            this.linkInfo = res.data.data
          }
        }
      })
    },
    dealResultMeta: function (docInfo) {
      this.title = docInfo.title === '' ? '未命名文档' : docInfo.title
      document.title = this.title
      this.author = (docInfo.userInfo && docInfo.userInfo.nickname) || '魔音用户'
      this.avatar = this.buildAvatar(this.author)
      this.avatarImage = (docInfo.userInfo && docInfo.userInfo.imageUrl) || ''
      this.time = formatDocTime(docInfo.updateTime)
    },
    buildAvatar: function (author) {
      if (author != null && author.length > 0) {
        return author.substr(0, 1)
      } else {
        return '魔'
      }
    },
    dealTextResult: function (docInfo) {
      if (docInfo.blockList != null && docInfo.blockList.length > 0) {
        var currentTs = 0
        var hasText = false
        docInfo.blockList.forEach((block, blockIndex) => {
          var textBlock = {}
          if (block.type === 'wordList') {
            textBlock.type = 'wordList'
            textBlock.words = []
            block.wordList.forEach((word, wordIndex) => {
              if (word.status !== 'delete' && word.del === 0) {
                word.id = `word-${blockIndex}-${wordIndex}`
                if (word.style != null && word.style.trim() !== '') {
                  try {
                    const { tags, styles } = JSON.parse(word.style)
                    if (tags != null) {
                      tags.forEach(item => {
                        if (item === 'strong') {
                          word.className = `${word.className} bold`
                        }
                        if (item === 'em') {
                          word.className = `${word.className} italic`
                        }
                        if (item === 'u') {
                          word.className = `${word.className} strike`
                        }
                      })
                    }
                    if (styles != null) {
                      word.style = styles
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }
                word.audioStart = currentTs
                word.audioEnd = currentTs + word.realAudioEnd - word.realAudioStart
                if (word.type === 'silence') {
                  word.word = ''
                } else {
                  hasText = true
                }
                currentTs = word.audioEnd
                textBlock.words.push(word)
                this.timeWords.push(word)
              }
            })
          } else if (block.type === 'h1') {
            textBlock.type = 'h1'
            textBlock.title = block.title
          }
          this.textResult.push(textBlock)
        })
        this.hasText = hasText
      }
    },
    dealMediaFragment: function (docInfo) {
      this.mediaList = docInfo.mediaList
      var fragments = []
      var curFragment = null
      function toFragment (word) {
        return {
          mediaId: word.mediaId,
          mediaStart: word.realAudioStart,
          mediaEnd: word.realAudioEnd,
          start: word.audioStart,
          end: word.audioEnd
        }
      }
      function switchFragment (curFragment, wordObj, fragments) {
        if (curFragment == null) {
          curFragment = toFragment(wordObj)
        } else {
          if (curFragment.mediaId !== wordObj.mediaId) {
            fragments.push(curFragment)
            curFragment = toFragment(wordObj)
          } else {
            if (wordObj.realAudioStart === curFragment.mediaEnd) {
              curFragment.mediaEnd = wordObj.realAudioEnd
              curFragment.end = wordObj.audioEnd
            } else {
              fragments.push(curFragment)
              curFragment = toFragment(wordObj)
            }
          }
        }
        return curFragment
      }
      for (var i = 0; i < this.timeWords.length; i++) {
        var wordObj = this.timeWords[i]
        if (!(wordObj.audioStart === wordObj.audioEnd)) {
          curFragment = switchFragment(curFragment, wordObj, fragments)
        }
      }
      if (curFragment != null) {
        fragments.push(curFragment)
        this.docDuration = curFragment.end
      }
      this.playMediaFragments = fragments
      this.curFragmentIndex = 0
      if (fragments.length === 0) {
        this.playMediaFragments.push({
          mediaId: 1,
          mediaStart: 0,
          mediaEnd: docInfo.duration,
          start: 0,
          end: docInfo.duration
        })
        this.docDuration = docInfo.duration
      }
      if (this.playMediaFragments.length > 0) {
        this.$refs.player.updateMediaItem(this.mediaList[this.playMediaFragments[0].mediaId - 1])
        this.$refs.player.setRealCurrentTime(this.playMediaFragments[0].mediaStart)
      }
    },
    requestReportViewLog: function () {
      var param = {
        version: this.docDetail.currentVersion,
        speed: this.$refs.player.getSpeed(),
        time: parseInt(this.currentTime),
        audioDocId: this.docId,
        interval: 3
      }
      if (this.userId != null) {
        param.userId = this.userId
      }
      reportViewRecord(param).then(res => {
        if (res.data.code !== 0) {
          console.log(res.data.desc)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    linkClick (time) {
      updateClick({docId: this.docId, userId: this.userId, clickTime: time})
    }
  }
}
</script>
<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  position: relative;
  .header {
    height: 56px;
    background: rgba(255,255,255,1);
    box-shadow:  0 2px 4px 0 rgba(226,226,226,0.5);
    .header-inner {
      margin: 0 auto;
      width: 1000px;
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .logo {
        width: 112px;
        height: 26px;
      }
      .close-button {
        cursor: pointer;
        width: 28px;
        height: 28px;
        background: url(../../../assets/web/top-close-normal.svg);
        &:hover {
          background: url(../../../assets/web/top-close-hover.svg);
        }
        &:active {
          background: url(../../../assets/web/top-close-active.svg);
        }
      }
      .top-test-icon {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .main-content {
    margin: 14px auto 10px auto;
    display: flex;
    flex-direction: row;
    .player-content {
      width: 1000px;
      .title {
        margin-top: 15px;
        font-size: 25px;
        font-weight: 500;
        color: rgba(51,51,51,1);
      }
      .info {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .avatar-img {
          width: 38px;
          height: 38px;
          border-radius: 19px;
        }
        .avatar {
          width: 38px;
          height: 38px;
          background: rgba(222, 40, 152, 1);
          border-radius: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
        }
        .meta {
          margin-left: 10px;
          margin-right: 10px;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          .name {
            font-size: 13px;
            font-weight: 500;
            color: rgba(34,34,34,1);
          }
          .time {
            margin-top: 2px;
            font-size: 12px;
            font-weight: normal;
            color: rgba(102,102,102,1);
          }
        }
        .share-button {
          width: 88px;
          height: 38px;
          background: rgba(255,255,255,1);
          border: 1px solid rgba(235,235,235,1);
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          & img {
            width: 18px;
            height: 18px;
          }
          & div {
            margin-left: 9px;
            font-size: 13px;
            font-weight: 500;
            color: rgba(34,34,34,1);
          }
        }
      }
    }
    .text-content {
      margin-left: 10px;
      width: 406px;
      background: rgba(255,255,255,1);
      border: 1px solid rgba(216,216,216,1);
      border-radius: 8px;
      box-shadow:  0 0 3px 0 rgba(0,0,0,0.21);
      position: relative;
      display: flex;
      flex-direction: column;
      .title-line {
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon {
          margin-left: 16px;
          width: 20px;
          height: 20px;
        }
        .title {
          margin-left: 8px;
          font-size: 18px;
          font-weight: 600;
          color: rgba(74,74,74,1);
        }
      }
      .line {
        height: 1px;
        background: rgba(216,216,216,0.5);
      }
      .close-button {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 12px;
        width: 28px;
        height: 28px;
      }
      .doc-content {
        position: relative;
        padding: 0 16px 16px 16px;
        margin-bottom: 16px;
        flex-grow: 1;
        height: 0;
        overflow-y: scroll;
        user-select: text;
        & h1 {
          cursor: default;
          font-size: 18px;
          font-weight: 500;
          color: #4A4A4A;
          margin-top: 0;
          margin-bottom: 12px;
        }
        .section-top {
          height: 16px;
        }
        .section {
          font-size: 15px;
          font-weight: normal;
          color: rgba(74,74,74,1);
          line-height: 27px;
          margin-bottom: 12px;
          text-align: left;
          & span {
            margin: 4px 0px 0px 0px;
            color: rgba(49, 49, 49, 1);
            word-break: break-word;
            cursor: pointer;
          }
          .strike {
            /* color: #b2b2b2; */
            /* text-decoration: line-through; */
            text-decoration: underline;
          }
          .bold {
            font-weight: bold;
          }
          .italic {
            font-style: italic;
          }
          .selected {
            background: rgba(0, 98, 255, 1);
            color: #ffffff;
            /*animation: bomb 0.4s;*/
          }
        }
        .no-text {
          position: absolute;
          z-index: 100;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          & img {
            width: 120px;
            height: 120px;
          }
          & div {
            margin-top: 12px;
            margin-bottom: 50px;
            font-size: 14px;
            font-weight: normal;
            color: rgba(102,102,102,1);
          }
        }
      }
    }
  }
  .loading {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    background: rgba(255,255,255,1);
    z-index: 10000;
    & img {
      width: 64px;
      height: 64px;
      -webkit-animation: changeright 1s linear infinite;
    }
  }
  @-webkit-keyframes changeright {
    0% {
      -webkit-transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  .not-exist {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(247,247,247,1);
    z-index: 10000;
    & img {
      width: 120px;
      height: 120px;
    }
    & div {
      margin-top: 12px;
      font-size: 14px;
      font-weight: normal;
      color: rgba(102,102,102,1);
    }
  }
  .code-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(238,241,249,1);
    z-index: 10000;
    .dialog {
      width: 492px;
      background: rgba(246,247,251,1);
      border-radius: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      .logo {
        position: absolute;
        top: 18px;
        left: 18px;
        width: 100px;
        height: 23px;
      }
      .header {
        background: transparent;
        height: 97px;
        display: flex;
        align-items: center;
        justify-content: center;
        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 16px;
        }
        .info {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          .phone {
            font-size: 12px;
            font-weight: normal;
            color: rgba(43,43,43,1);
          }
          .share {
            font-size: 14px;
            font-weight: 500;
            color: rgba(43,43,43,1);
          }
        }
      }
      .code-area {
        height: 176px;
        background: rgba(255,255,255,1);
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        .title {
          margin-top: 47px;
          margin-left: 32px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
        .input-line {
          margin-top: 11px;
          margin-left: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .code {
            padding: 0 12px;
            width: 308px;
            height: 38px;
            line-height: 38px;
            border: 1px solid rgba(235,235,235,1);
            border-radius: 4px;
            outline: none;
          }
          .view-button {
            margin-left: 12px;
            width: 84px;
            height: 38px;
            background: rgba(0,112,255,1);
            border-radius: 4px;
            font-size: 14px;
            font-weight: normal;
            color: rgba(255,255,255,1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .wechat-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(238,241,249,1);
    z-index: 10000;
    .dialog {
      width: 494px;
      height: 374px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 494px;
        height: 374px;
      }
      .logo {
        position: absolute;
        top: 18px;
        left: 19px;
        width: 100px;
        height: 23px;
      }
      .user-info {
        margin-top: 57px;
        height: 36px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 16px;
        }
        .info {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          .phone {
            font-size: 12px;
            font-weight: normal;
            color: rgba(43,43,43,1);
          }
          .share {
            font-size: 14px;
            font-weight: 500;
            color: rgba(43,43,43,1);
          }
        }
      }
      .qr {
        margin-top: 17px;
        position: relative;
        width: 152px;
        height: 152px;
        border: 8px solid #BBCFFF;
        background: white;
        overflow: hidden;
      }
      .tip-text {
        position: relative;
        margin-top: 26px;
        font-size: 12px;
        font-weight: normal;
        color: rgba(255,255,255,0.9);
      }
      .tip-image {
        position: relative;
        margin-top: 15px;
        width: 100px;
        height: 18px;
      }
    }
  }
}
</style>
