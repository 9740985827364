import axios from 'axios'
import md5 from 'js-md5'
import { getUid, getToken, getSalt, removeUserInfo } from './storage'
import { isTestEnv } from './const'
import { getAppVersion } from './common'

let apiUrl = ''

if (!isTestEnv()) {
  apiUrl = 'https://mapi.moyincloud.com'
} else {
  apiUrl = 'https://dev.mapi.moyincloud.com'
}

// create an axios instance
const service = axios.create({
  baseURL: apiUrl, // api的baseUrl
  timeout: 60 * 2 * 1000, // request timeout
  headers: {
  }
})

service.interceptors.request.use(
  config => {
    fillParam(config)
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 1001 || res.code === 1003) {
      removeUserInfo()
    }
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

function fillParam (config) {
  if (config.params == null) config.params = {}
  var uid = getUid()
  var token = getToken()
  var salt = getSalt()
  config.params.v = getAppVersion()
  config.params.t = new Date().getTime()
  if (uid != null) {
    config.params.uid = uid
  }
  if (token != null) {
    config.params.token = token
  }
  config.params.s = caculateSignature(config.params, salt)
}

function caculateSignature (params, salt) {
  var array = []
  for (var key in params) {
    array.push(key)
  }
  array.sort()
  var string = ''
  for (var i = 0; i < array.length; i++) {
    key = array[i]
    string += key
    string += params[key]
  }
  string += salt
  return md5(string).toLocaleUpperCase()
}

export default service
