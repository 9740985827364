<template>
  <div class="progress-bar">
    <div class="current">{{ currentText }}</div>
    <div ref="container" class="seek-bar-container">
      <input
        ref="progress"
        class="seek-bar"
        type="range"
        @mousedown="onMouseDown"
        @input="onInput"
        @mouseup="onMouseUp"
        @touchstart="onTouchStart"
        @touchend="onTouchEnd"
        step="any"
        min="0"
        max="0"
        value="0"
        aria-label="进度"
      />
    </div>
    <div class="total">{{ totalText }}</div>
  </div>
</template>
<script>
import { formatPlayerTime } from '../../../util/time'

export default {
  name: 'MobileSeekBar',
  props: {
    current: {
      type: Number,
      default: 0,
      required: true
    },
    total: {
      type: Number,
      default: 0,
      required: true
    },
  },
  watch: {
    current (val) {
      this.currentText = formatPlayerTime(val / 1000)
      if (this.changing) {
        return
      }
      this.$refs.progress.value = val
      this.updateColors()
    },
    total (val) {
      this.totalText = formatPlayerTime(val / 1000)
      this.$refs.progress.max = val
      this.updateColors()
    }
  },
  data () {
    return {
      changing: false,
      buffered: null,
      currentText: '00:00',
      totalText: '00:00'
    }
  },
  mounted () {
    this.updateColors()
  },
  methods: {
    onMouseDown: function () {
      this.changing = true
      this.$emit('start')
    },
    onInput: function () {
      this.updateColors()
      this.$emit('change', this.$refs.progress.value)
    },
    onMouseUp: function () {
      if (this.changing) {
        this.changing = false
        this.$emit('end')
      }
    },
    onTouchStart: function () {
      console.log('onTouchStart')
      this.changing = true
      this.$emit('start')
    },
    onTouchEnd: function () {
      console.log('onTouchEnd')
      if (this.changing) {
        this.changing = false
        this.$emit('end')
      }
    },
    updateColors: function () {
      let colors = {
        base: 'rgba(255, 255, 255, 0.31)',
        buffered: 'rgba(255, 98, 62, 1)',
        played: 'rgba(255, 98, 62, 1)'
      }
      var value = parseFloat(this.$refs.progress.value)
      var max = parseFloat(this.$refs.progress.max)
      if (max === 0) {
        const gradient = ['to right']
        gradient.push(colors.base + '0%')
        gradient.push(colors.base + '0%')
        gradient.push(colors.base + '100%')

        this.$refs.container.style.background = 'linear-gradient(' + gradient.join(',') + ')'
      } else {
        const gradient = ['to right']
        gradient.push(colors.played + (value / max) * 100 + '%')
        if (this.buffered != null && this.buffered.length > 0) {
          var bufferEnd = this.buffered.end(this.buffered.length - 1)
          gradient.push(colors.buffered + (value / max) * 100 + '%')
          gradient.push(colors.buffered + (bufferEnd / max) * 100 + '%')
          gradient.push(colors.base + (bufferEnd / max) * 100 + '%')
        } else {
          gradient.push(colors.base + (value / max) * 100 + '%')
        }
        gradient.push(colors.base + '100%')
        this.$refs.container.style.background = 'linear-gradient(' + gradient.join(',') + ')'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.progress-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  .current {
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    font-size: 10px;
    font-weight: normal;
    color: rgba(255,255,255,1);
  }
  .seek-bar-container {
    flex-grow: 1;
    position: relative;
    height: 4px;
    border-radius: 2px;
    opacity: 1;
    transition: opacity cubic-bezier(0.4, 0, 0.6, 1) 600ms;
    .seek-bar {
      -webkit-appearance: none;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      height: 8px;
      top: -2px;
      z-index: 1;
      cursor: pointer;
    }
    .seek-bar::-webkit-slider-runnable-track {
      width: 100%;
      height: 10px;
      background: 0 0;
      color: transparent;
      border: none;
    }
    .seek-bar::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      border-radius: 5px;
      height: 10px;
      width: 10px;
      background: rgba(255,98,62,1);
    }
  }
  .total {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    font-size: 10px;
    font-weight: normal;
    color: rgba(255,255,255,1);
  }
}
</style>
