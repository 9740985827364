<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: PingFangSC-Regular, PingFang SC;
}
html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
}
button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  outline: none;
}
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
