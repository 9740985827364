const kVersion = '1.1.3'

export function getAppVersion () {
  return kVersion
}

export function isInWeixin() {  
  var ua = navigator.userAgent.toLowerCase();  
  if (ua.match(/MicroMessenger/i) == "micromessenger") {  
      return true;  
  } else {  
      return false;  
  }  
}

export function isMobile () {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return true
  } else if (/(Android)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

export function isInDingDing () {
  if (/(DingTalk)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

export function isInFeishu () {
  if (/(Feishu)/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
