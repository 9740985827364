<template>
  <div class="player">
    <div class="video-content" :style="{ maxHeight: !fullscreen ? '576px' : '' }" ref="videoContent" @mousemove="onVideoMouseMove" @mouseleave="onVideoMouseLeave">
      <video
        ref="video"
        crossorigin="anonymous"
        x5-playsinline=""
        playsinline=""
        webkit-playsinline=""
        x-webkit-airplay="allow"
        preload="auto"
        @play="onPlay"
        @playing="onPlaying"
        @pause="onPause"
        @ended="onEnded"
        @error="onError"
        @waiting="onWaiting"
        @loadedmetadata="onLoadedMetaData"
        :style="{ width: (videoWidth > 0 && !fullscreen) ? videoWidth + 'px' : '', height: (videoHeight > 0 && !fullscreen) ? videoHeight + 'px' : '' }"
        />
      <div class="slide-content" v-if="onlySlide && slideList[selectedSlideIndex]">
        <img :src="slideList[selectedSlideIndex].url" />
      </div>
      <div class="play-control" @click="clickPlayControl" :style="{ 'background-color': (hasLink && (playEnded || (current > 0 && !playing && !loading))) ? 'rgba(0,0,0,0.21)' : '' }">
        <div v-if="hasLink && (playEnded || (current > 0 && !playing && !loading))">
          <a @click.stop="clickLinkButton"  class="link-button" :style="{ color: linkInfo.content == '' ? 'rgba(255,255,255,0.5)' : linkInfo.textColor, 'border-radius': linkInfo.cornerSize + 'px', 'background-color': linkInfo.buttonColor }" target="_blank" :href="linkInfo.linkUrl">{{ linkInfo.content == '' ? '查看文档' : linkInfo.content }}</a>
          <div class="replay-button">
            <img v-if="playEnded" src="../../../assets/player/icon-replay.svg" />
            <img v-else src="../../../assets/player/icon-play.svg" />
            <div>{{ playEnded ? '重新播放' : '继续播放' }}</div>
          </div>
        </div>
        <img v-else-if="!playing && !loading && !outLoading" src="../../../assets/player/play.svg" />
      </div>
      <div class="control-bar" v-show="controlBarVisible" @click.stop @mousemove.stop @mouseenter="onControlBarEnter">
        <div class="space-line"></div>
        <div class="progress">
          <div class="bg" ref="progressBg"></div>
          <div class="indicator" v-for="(item, index) in slideIndicators" :key="index" :style="{ left: item.left + 'px' }"></div>
          <input ref="progress" class="range" type="range"
              step="any" min="0" max="0" value="0"
              @mousedown="onProgressMouseDown" @input="onProgressInput" @mouseup="onProgressMouseUp"/>
        </div>
        <div class="time"><span class="current">{{ currentText }}</span> {{ duration > 0 ? '/ ' + durationText : '' }}</div>
        <div class="button-line">
          <button class="play-button" @click="clickPlayButton">
            <img v-if="playing" src="../../../assets/web/pause.svg" />
            <img v-else src="../../../assets/web/play.svg" />
          </button>
          <button class="action-button" :class="enableBackward ? '' : 'action-button-disable'" @click="clickBackwardButton">
            <img v-if="enableBackward" src="../../../assets/web/backward-on.svg" />
            <img v-else src="../../../assets/web/backward-off.svg" />
          </button>
          <button class="action-button" :class="enableForward ? '' : 'action-button-disable'" @click="clickForwardButton">
            <img v-if="enableForward" src="../../../assets/web/forward-on.svg" />
            <img v-else src="../../../assets/web/forward-off.svg" />
          </button>
          <speed-button class="speed-button" :speed="speed" @selected="onSpeedSelected" />
          <div class="space"></div>
          <button class="transcribe-button" @click="clickTranscribeButton">
            <img v-if="transcribeOn" src="../../../assets/web/transcribe-on.svg" />
            <img v-else src="../../../assets/web/transcribe-off.svg" />
            <div>文字稿</div>
          </button>
          <button class="action-button" @click="clickPptButton" v-if="slideList.length > 0">
            <img src="../../../assets/web/ppt-off.svg" />
          </button>
          <volume-button class="action-button" :volume="volume" @changed="onVolumeChanged" />
          <button class="action-button" @click="clickFullscreenButton">
            <img v-if="!fullscreen" src="../../../assets/web/enter-fullscreen.svg" />
            <img v-else src="../../../assets/web/exit-fullscreen.svg" />
          </button>
        </div>
      </div>
      <div class="spinner-container" v-show="loading">
        <img src="../../../assets/video_loading.svg" alt="" />
      </div>
    </div>
    <div class="slide-list">
    </div>
  </div>
</template>
<script>
import { loadPlayerSetting, savePlayerSetting } from '../../../util/storage'
import { formatPlayerTime } from '../../../util/time'
import { isSupportM3u8 } from '../../../util/media'
import SpeedButton from './SpeedButton.vue'
import VolumeButton from './VolumeButton.vue'

export default {
  name: 'WebPlayer',
  components: {
    SpeedButton,
    VolumeButton
  },
  props: {
    videoWidth: {
      type: Number,
      required: true,
      default: 1000,
    },
    videoHeight: {
      type: Number,
      required: true,
      default: 576
    },
    current: {
      type: Number,
      default: 0,
      required: true
    },
    duration: {
      type: Number,
      default: 0,
      required: true
    },
    slideList: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    transcribeOn: {
      type: Boolean,
      required: true,
      default: false
    },
    outLoading: {
      type: Boolean,
      required: true,
      default: false
    },
    hasLink: {
      type: Boolean,
      required: true,
      default: false
    },
    linkInfo: {
      type: Object,
      required: true,
      default: null
    },
    playEnded: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  watch: {
    current (val) {
      this.currentText = formatPlayerTime(val / 1000)
      this.$refs.progress.value = val
      this.updateRangeStyle(this.$refs.progress, this.$refs.progressBg)
    },
    duration (val) {
      this.durationText = formatPlayerTime(val / 1000)
      this.$refs.progress.max = val
    }
  },
  computed: {
    enableForward: function () {
      return this.current < this.duration
    },
    enableBackward: function () {
      return this.current > 0
    }
  },
  data () {
    return {
      playing: false,
      wasPlaying: false,
      pauseForSeek: false,
      loading: false,
      loadingTipTimer: null,
      canPaused: false,

      controlBarVisible: true,
      recentMouseMovement: false,

      currentText: '00:00',
      durationText: '00:00',
      speed: 1,
      volume: 100,
      onlySlide: false,
      selectedSlideIndex: 0,
      fullscreen: false,
      slideIndicators: []
    }
  },
  mounted () {
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    })
    document.addEventListener('webkitfullscreenchange', () => {
      if (document.webkitFullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    })
    document.addEventListener('mozfullscreenchange', () => {
      if (document.mozFullScreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    })
    document.addEventListener('MSFullscreenChange', () => {
      if (document.msFullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    })
    var setting = loadPlayerSetting()
    if (setting.speed != null) {
      this.speed = setting.speed
      this.$refs.video.playbackRate = this.speed
    }
  },
  methods: {
    init: function () {
      this.playing = false
      this.wasPlaying = false
      this.pauseForSeek = false
      this.loading = false
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
      this.controlBarVisible = true
      this.recentMouseMovement = false
      this.currentText = '00:00'
      this.durationText = '00:00'
      this.onlySlide = false
      this.selectedSlideIndex = 0
      this.fullscreen = false
      this.slideIndicators = []
      this.$refs.video.src = null
    },
    onPlay: function () {
      this.onPlayStateChange()
    },
    onPlaying: function () {
      this.playing = true
      this.loading = false
      this.$refs.video.playbackRate = this.speed
      this.$refs.video.volume = this.volume / 100
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
      this.$emit('playing')
    },
    onPause: function () {
      if (this.pauseForSeek) {
        this.pauseForSeek = false
        return
      }
      this.playing = false
      this.loading = false
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
      this.onPlayStateChange()
      this.$emit('pause')
    },
    onEnded: function () {
      this.$emit('ended')
    },
    onError: function () {
      if (this.$refs.video && !this.$refs.video.error) {
        return
      }
      this.pauseForSeek = false
      this.playing = false
      this.loading = false
      this.$emit('error')
    },
    onWaiting: function () {
      this.$emit('waiting')
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
      this.loadingTipTimer = setTimeout(() => {
        this.loading = true
      }, 3000)
    },
    onLoadedMetaData: function () {
      var videoWidth = this.$refs.video.videoWidth
      var videoHeight = this.$refs.video.videoHeight
      this.$emit('videoSize', videoWidth, videoHeight)
    },
    onPlayStateChange: function () {
      if (this.$refs.video.ended && !this.$refs.video.paused) {
        this.pause()
      }
      this.computeOpacity()
    },
    computeOpacity: function () {
      const videoIsPaused = this.$refs.video.paused
      if (videoIsPaused || this.recentMouseMovement) {
        this.controlBarVisible = true
        this.$refs.videoContent.style.cursor = ''
      } else {
        setTimeout(() => {
          if (this.playing) {
            this.controlBarVisible = false
            this.$refs.videoContent.style.cursor = 'none'
          }
        }, 1000)
      }
    },
    onVideoMouseMove: function () {
      this.recentMouseMovement = true
      this.computeOpacity()
      clearTimeout(this.mouseMoveTimer)
      var _this = this
      this.mouseMoveTimer = setTimeout(() => {
        _this.onMouseStill()
      }, 3000)
    },
    onVideoMouseLeave: function () {
      this.onMouseStill()
    },
    onControlBarEnter: function () {
      clearTimeout(this.mouseMoveTimer)
      this.mouseMoveTimer = null
    },
    onMouseStill: function () {
      this.recentMouseMovement = false
      this.computeOpacity()
    },
    onProgressMouseDown: function () {
      this.wasPlaying = !this.$refs.video.paused
      if (this.wasPlaying) {
        this.pause()
        this.pauseForSeek = true
      }
    },
    onProgressInput: function () {
      const progress = this.$refs.progress.value
      this.updateRangeStyle(this.$refs.progress, this.$refs.progressBg)
      this.$emit('seekTime', parseInt(progress))
    },
    onProgressMouseUp: function () {
      if (this.wasPlaying) {
        this.play()
        this.wasPlaying = false
      }
    },
    updateRangeStyle: function (rangeEle, bgEle) {
      const min = rangeEle.min
      const max = rangeEle.max
      const val = rangeEle.value
      if (max - min === 0) {
        bgEle.style.backgroundSize = '0% 100%'
      } else {
        bgEle.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
      }
    },
    onSpeedSelected: function (speed) {
      this.speed = speed
      this.$refs.video.playbackRate = this.speed
      var setting = {
        speed: speed
      }
      savePlayerSetting(setting)
    },
    getSpeed: function () {
      return this.speed
    },
    onVolumeChanged: function (volume) {
      this.volume = volume
      this.$refs.video.volume = volume / 100
    },
    clickPlayControl: function () {
      this.$emit('playControl')
    },
    clickLinkButton: function () {
      const time = parseInt(this.$refs.video.currentTime)
      this.$emit('linkClick', time)
    },
    clickPlayButton: function () {
      this.$emit('playControl')
    },
    clickBackwardButton: function () {
      this.backward()
    },
    clickForwardButton: function () {
      this.forward()
    },
    clickTranscribeButton: function () {
      this.$emit('transcribeControl')
    },
    clickPptButton: function () {
      this.onlySlide = !this.onlySlide
    },
    clickFullscreenButton: function () {
      if (this.fullscreen) {
        this.exitFullscreen()
      } else {
        if (this.isSafariMobile()) {
          var video = this.$refs.video
          if (!this.isMediaPlaying(video)) {
            this.$emit('playControl')
            setTimeout(() => {
              if (video.webkitEnterFullScreen) {
                video.webkitEnterFullScreen()
              }
            }, 50)
          } else {
            if (video.webkitEnterFullScreen) {
              video.webkitEnterFullScreen()
            }
          }
        } else {
          this.enterFullscreen(this.$refs.videoContent)
        }
      }
    },
    enterFullscreen(el) {
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    },
    isSafariMobile: function () {
      var ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('iphone') > -1 &&
          ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
          ua.indexOf('ios') === -1 && ua.indexOf('browser') === -1) {
        return true
      } else {
        return false
      }
    },
    isMediaPlaying: function (el) {
      return el.currentTime > 0 && !el.paused && !el.ended && el.readyState > 2
    },
    clickSlideItem: function (item, index) {
      this.selectedSlideIndex = index
      this.$emit('seekTime', item.beginTime + 200)
    },
    updateMediaItem: function (mediaItem) {
      const { videoUrl, coverUrl, videoSmartUrl, videoM3u8Url } = mediaItem
      if (videoM3u8Url != null && videoM3u8Url !== '' && isSupportM3u8()) {
        this.$refs.video.src = videoM3u8Url
      } else {
        this.$refs.video.src = videoSmartUrl !== '' ? videoSmartUrl : videoUrl
      }
      this.$refs.video.poster = coverUrl
      this.$refs.video.playbackRate = this.speed
      if (mediaItem.width > 0 && mediaItem.height > 0) {
        this.portrait = mediaItem.height > mediaItem.width
        this.$emit('videoSize', mediaItem.width, mediaItem.height)
      }
    },
    getRealCurrentTime: function() {
      return this.$refs.video.currentTime * 1000
    },
    setRealCurrentTime: function(currentTime) {
      this.$refs.video.currentTime = currentTime / 1000
    },
    pause: function () {
      if (this.canPaused) {
        this.$refs.video.pause()
        this.canPaused = false
      }
    },
    play: function () {
      this.canPaused = false
      this.$refs.video.play().then(() => {
        this.canPaused = true
      }).catch(e => {
        console.log(e)
        this.canPaused = false
      })
    },
    forward: function () {
      if (this.slideList.length > 0) {
        if (this.selectedSlideIndex < this.slideList.length - 1) {
          this.clickSlideItem(this.slideList[this.selectedSlideIndex + 1], this.selectedSlideIndex + 1)
        }
      } else {
        this.$emit('seekTime', this.current + 15000 < this.duration ? this.current + 15000 : this.duration)
      }
    },
    backward: function () {
      if (this.slideList.length > 0) {
        if (this.selectedSlideIndex > 0) {
          this.clickSlideItem(this.slideList[this.selectedSlideIndex - 1], this.selectedSlideIndex - 1)
        }
      } else {
        this.$emit('seekTime', this.current - 15000 > 0 ? this.current - 15000 : 0)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.player {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .video-content {
    background: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    & video {
      max-width: 100%;
      max-height: 100%;
    }
    .slide-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: black;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .link-button {
      min-width: 180px;
      max-width: 360px;
      height: 50px;
      line-height: 50px;
      text-decoration: none;
      cursor: pointer;
      padding: 0 16px;
      background: rgba(62,127,255,1);
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255,255,255,1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      display: block;
    }
    .link-button-top {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .play-control {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 88px;
        height: 88px;
        cursor: pointer;
      }
      .replay-button {
        cursor: pointer;
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        & img {
          width: 18px;
          height: 18px;
        }
        & div {
          margin-left: 9px;
          margin-right: 10px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255,255,255,1);
        }
      }
    }
    .control-bar {
      position: absolute;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
      .space-line {
        height: 80px;
      }
      .progress {
        margin-left: 20px;
        margin-right: 20px;
        height: 10px;
        position: relative;
        cursor: pointer;
        .bg {
          height: 4px;
          margin-top: 3px;
          width: 100%;
          background: rgba(255,255,255, 0.39);
          background-image: linear-gradient(#FFFFFF, #FFFFFF);
          background-size: 0% 100%;
          background-repeat: no-repeat;
          border-radius: 4px;
        }
        .indicator {
          width: 4px;
          height: 4px;
          background: rgba(210,210,210,1);
          border-radius: 2px;
          position: absolute;
          top: 3px;
        }
        .range {
          outline: 0;
          position: absolute;
          top: 1px;
          left: 0;
          width: 100%;
          bottom: 0;
          height: 4px;
          margin-left: 0;
          margin-right: 0;
          -webkit-appearance: none;
          background: transparent;
          cursor: pointer;
        }
        .range::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: none;
          height: 10px;
          width: 6px;
          background: rgba(0,112,255,1);
          border-radius: 1px;
        }
        .range::-webkit-slider-runnable-track {
          background: rgba(255,255,255,0);
        }
      }
      .time {
        margin: 4px 0px 0px 20px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,0.64);
        .current {
          color: rgba(255,255,255,1);
        }
      }
      .button-line {
        margin: 0 20px;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .play-button {
          width: 24px;
          height: 24px;
          cursor: pointer;
          & img {
            width: 24px;
            height: 24px;
          }
        }
        .action-button {
          margin-left: 24px;
          width: 24px;
          height: 24px;
          cursor: pointer;
          & img {
            width: 24px;
            height: 24px;
          }
        }
        .action-button-disable {
          cursor: not-allowed;
        }
        .speed-button {
          margin-left: 24px;
          cursor: pointer;
        }
        .space {
          flex-grow: 1;
        }
        .transcribe-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          & img {
            width: 24px;
            height: 24px;
          }
          & div {
            margin-left: 6px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255,255,255,1);
          }
        }
      }
    }
    .spinner-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-shrink: 1;
      & img {
        width: 57px;
        height: 57px;
        -webkit-animation: changeright 1s linear infinite;
      }
    }
    @-webkit-keyframes changeright {
      0% {
        -webkit-transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
</style>
