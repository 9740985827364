<template>
  <div class="brand" v-show="visible">
    <img class="image" src="../assets/component/logo.svg" />
    <div class="slogon">重新定义视频沟通</div>
    <img class="close-button" src="../assets/component/close.svg" @click="clickCloseButton" />
  </div>
</template>
<script>
export default {
  name: 'Brand',
  data () {
    return {
      visible: true
    }
  },
  methods: {
    clickCloseButton: function () {
      this.visible = false
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="scss">
.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 74px;
  background: rgba(235,244,255,1);
  z-index: 100;
  .image {
    margin-left: 16px;
    width: 100px;
    height: 24px;
  }
  .slogon {
    flex-grow: 1;
    margin-left: 16px;
    font-size: 14px;
    font-weight: normal;
    color: rgba(102,102,102,1);
  }
  .close-button {
    margin-right: 16px;
    width: 16px;
    height: 16px;
  }
}
</style>
