export function formatDocTime(time) {
  let timeDate = new Date(time)
  if (isNaN(timeDate)) {
    timeDate = new Date(time.replace(/-/g, '/'))
  }
  let ptime = timeDate.getTime()
  const pYear = timeDate.getFullYear()
  const pMonth = timeDate.getMonth() + 1
  const pDay = timeDate.getDate()

  const twentyFourHours = 24 * 60 * 60 * 1000
  const fortyEightHours = 24 * 60 * 60 * 1000 * 2
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const today = `${year}-${month}-${day}`
  const todayTime = new Date(today).getTime()
  const yesterdayTime = new Date(todayTime - twentyFourHours).getTime()
  const lastYesterdayTime = new Date(todayTime - fortyEightHours).getTime()

  if (ptime >= todayTime) {
    return '今天 ' + time.split(' ')[1].substring(0, 5)
  } else if (ptime < todayTime && yesterdayTime <= ptime) {
    return '昨天 ' + time.split(' ')[1].substring(0, 5)
  } else if (ptime < yesterdayTime && lastYesterdayTime <= ptime) {
    return '前天 ' + time.split(' ')[1].substring(0, 5)
  } else if (pYear === year) {
    return `${pMonth}-${pDay} ${time.split(' ')[1].substring(0, 5)}`
  } else {
    return `${pYear}-${pMonth}-${pDay} ${time.split(' ')[1].substring(0, 5)}`
  }
}

export function formatPlayerTime(sec) {
  function add0(cnt) {
    return cnt <= 9 ? '0' + cnt : '' + cnt
  }
  var hour = sec >= 3600 ? parseInt(sec / 3600) : 0
  var minute = sec >= 60 ? parseInt((sec % 3600) / 60) : 0
  var second = sec >= 1 ? parseInt(sec % 60) : 0

  if (hour > 0) {
    return hour + ':' + add0(minute) + ':' + add0(second)
  } else {
    return add0(minute) + ':' + add0(second)
  }
}
