import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueClipboard from 'vue2-clipboard'
import Toasted from 'vue-toasted'

Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(Toasted)

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
}).$mount('#app')
