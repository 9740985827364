<template>
  <div class="button" v-clickoutside="hideVolumeMenu">
    <img v-if="volume === 0" class="image" @click="clickVolumeButton" src="../../../assets/web/volume-off.svg" />
    <img v-else class="image" @click="clickVolumeButton" src="../../../assets/web/volume-on.svg" />
    <div ref="volumeMenu" class="menu" v-show="menuVisible" @mousedown="onMenuMouseDown">
      <div class="text">{{ volume }}</div>
    </div>
  </div>
</template>
<script>
import Clickoutside from 'element-ui/src/utils/clickoutside'

export default {
  name: 'VolumeButton',
  directives: { Clickoutside },
  props: {
    volume: {
      type: Number,
      required: true,
      default: 100
    }
  },
  watch: {
    volume (val) {
      this.$refs.volumeMenu.style.backgroundSize = '100% ' + (100 - val) + '%'
    }
  },
  data () {
    return {
      menuVisible: false,
      volumeDragging: false,
      dragStartVolume: 100,
      dragStartY: -1
    }
  },
  created () {
    document.addEventListener('mouseup', this.onDocumentMouseUp, true)
  },
  destroyed () {
    document.removeEventListener('mouseup', this.onDocumentMouseUp)
  },
  methods: {
    hideVolumeMenu: function () {
      this.menuVisible = false
    },
    clickVolumeButton: function () {
      this.menuVisible = !this.menuVisible
    },
    onMenuMouseDown: function (event) {
      const menuRect = this.$refs.volumeMenu.getBoundingClientRect()
      const volumeChange = parseInt((event.clientY - menuRect.y) * 100 / 88)
      this.$emit('changed', (100 - volumeChange))

      this.volumeDragging = true
      this.dragStartVolume = 100 - volumeChange
      this.dragStartY = event.screenY
      document.addEventListener('mousemove', this.onDocumentMouseMove, true)
    },
    onDocumentMouseMove: function (event) {
      if (this.volumeDragging) {
        var y = event.screenY
        var volumeChange = parseInt((y - this.dragStartY) * 100 / 88)
        var destVolume = this.dragStartVolume - volumeChange
        if (destVolume < 0) {
          destVolume = 0
        } else if (destVolume > 100) {
          destVolume = 100
        }
        this.$emit('changed', destVolume)
      }
    },
    onDocumentMouseUp: function () {
      document.removeEventListener('mousemove', this.onDocumentMouseMove)
      this.volumeDragging = false
      this.dragStartVolume = this.volume
      this.dragStartY = -1
    }
  }
}
</script>
<style scoped lang="scss">
.button {
  position: relative;
  width: 24px;
  height: 24px;
  .image {
    width: 24px;
    height: 24px;
  }
  .menu {
    position: absolute;
    bottom: 30px;
    left: -10px;
    width: 44px;
    height: 88px;
    background: #EBEBEB;
    background-image: linear-gradient(#FFFFFF, #FFFFFF);
    background-size: 100% 0%;
    background-repeat: no-repeat;
    border-radius: 8px;
    box-shadow:  0 0 2px 0 rgba(0,0,0,0.3);
    cursor: pointer;
    .text {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: rgba(153,153,153,1);
    }
  }
}
</style>
