<template>
  <div class="player">
    <div class="video-content" :style="{ maxHeight: !fullscreen ? '375px' : '' }" ref="videoContent" @mousemove="onVideoMouseMove" @mouseleave="onVideoMouseLeave">
      <video ref="video"
        class="video"
        crossorigin="anonymous"
        playsinline="true"
        webkit-playsinline=""
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x-webkit-airplay="allow"
        preload="auto"
        @play="onPlay"
        @playing="onPlaying"
        @pause="onPause"
        @ended="onEnded"
        @error="onError"
        @waiting="onWaiting"
        @loadedmetadata="onLoadedMetaData"
        :style="{ width: (videoWidth > 0 && !fullscreen) ? videoWidth + 'px' : '', height: (videoHeight > 0 && !fullscreen) ? videoHeight + 'px' : '' }"
        />
      <div class="slide-content" v-if="onlySlide && slideList[selectedSlideIndex]">
        <img :src="slideList[selectedSlideIndex].url" />
      </div>
      <div class="play-control" @click="clickPlayControl" :style="{ 'background-color': (hasLink && (playEnded || (current > 0 && !playing && !loading))) ? 'rgba(0,0,0,0.21)' : '' }">
        <div v-if="hasLink && (playEnded || (current > 0 && !playing && !loading))">
          <a class="link-button" @click.stop="clickLinkButton"  :style="{ color: linkInfo.content == '' ? 'rgba(255,255,255,0.5)' : linkInfo.textColor, 'border-radius': linkInfo.cornerSize + 'px', 'background-color': linkInfo.buttonColor }" target="_blank" :href="linkInfo.linkUrl">{{ linkInfo.content == '' ? '查看文档' : linkInfo.content }}</a>
          <div class="replay-button" @click.stop="clickPlayButton">
            <img v-if="playEnded" src="../../../assets/player/icon-replay.svg" />
            <img v-else src="../../../assets/player/icon-play.svg" />
            <div>{{ playEnded ? '重新播放' : '继续播放' }}</div>
          </div>
        </div>
        <img @click.stop="clickPlayButton" v-else-if="!playing && !loading" src="../../../assets/player/play.svg"/>
        <img @click.stop="clickPlayButton" v-if="playing && !loading && controlBarVisible && fullscreen" src="../../../assets/player/pause.svg" />
      </div>
      <div class="control-bar" :class="{ 'control-bar-noslide': slideList.length === 0, 'control-bar-portrait': portrait }" @click.stop v-show="controlBarVisible">
        <seek-bar class="progress-bar" ref="progress" :current="current" :total="duration" @start="onSeekBarStart" @change="onSeekBarChange" @end="onSeekBarEnd" />
        <button class="ppt-button" @click="clickPptButton">
          <img v-if="onlySlide" src="../../../assets/player/ppt-on.svg" />
          <img v-else src="../../../assets/player/ppt-off.svg" />
        </button>
        <button class="fullscreen-button" @click="clickFullscreenButton">
          <img v-if="fullscreen" src="../../../assets/player/exit-fullscreen.svg" />
          <img v-else src="../../../assets/player/enter-fullscreen.svg" />
        </button>
        <button class="speed-button" @click="clickSpeedButton">
          <img v-if="speed === 1.2" src="../../../assets/player/speed-1.2.svg"/>
          <img v-else-if="speed === 1.5" src="../../../assets/player/speed-1.5.svg"/>
          <img v-else-if="speed === 1.7" src="../../../assets/player/speed-1.7.svg"/>
          <img v-else-if="speed === 2.0" src="../../../assets/player/speed-2.0.svg"/>
          <img v-else src="../../../assets/player/speed-1.0.svg"/>
        </button>
        <button class="forward-button" @click="clickForwardButton">
          <img src="../../../assets/player/forward.svg" />
        </button>
        <button class="backward-button" @click="clickBackwardButton">
          <img src="../../../assets/player/backward.svg" />
        </button>
      </div>
      <div class="spinner-container" v-show="loading">
        <img src="../../../assets/video_loading.svg" alt="" />
      </div>
    </div>
    <div class="slide-list" ref="slideList" v-show="slideList.length > 0">
      <div class="slide-list-inner">
        <div class="slide-item" v-for="(item, index) in slideList" :key="index" @click="clickSlideItem(item, index)">
          <div class="image-wrapper" :class="index === selectedSlideIndex ? 'image-wrapper-active' : ''">
            <img :src="item.url" />
          </div>
          <div class="text">{{ index + 1 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isMobile } from '../../../util/common'
import { isSupportM3u8 } from '../../../util/media'
import SeekBar from './SeekBar.vue'

export default {
  name: 'MobilePlayer',
  components: {
    SeekBar
  },
  props: {
    videoWidth: {
      type: Number,
      required: true,
      default: 0,
    },
    videoHeight: {
      type: Number,
      required: true,
      default: 0
    },
    speed: {
      type: Number,
      required: true,
      default: 1
    },
    current: {
      type: Number,
      default: 0,
      required: true
    },
    duration: {
      type: Number,
      default: 0,
      required: true
    },
    slideList: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    hasLink: {
      type: Boolean,
      required: true,
      default: false
    },
    linkInfo: {
      type: Object,
      required: true,
      default: null
    },
    playEnded: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  watch: {
    duration () {
      this.onLoadedMetaData()
    },
    speed (val) {
      this.$refs.video.playbackRate = val
    }
  },
  data () {
    return {
      playing: false,
      wasPlaying: false,
      pauseForSeek: false,
      loading: false,
      loadingTipTimer: null,
      canPaused: false,
      portrait: false,
      firstPlay: true,

      controlBarVisible: true,
      recentMouseMovement: false,
      lastClickTime: 0,
      clickTimer: null,

      onlySlide: false,
      selectedSlideIndex: 0,
      fullscreen: false
    }
  },
  mounted () {
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    })
    document.addEventListener('webkitfullscreenchange', () => {
      if (document.webkitFullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    })
    document.addEventListener('mozfullscreenchange', () => {
      if (document.mozFullScreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    })
    document.addEventListener('MSFullscreenChange', () => {
      if (document.msFullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    })
  },
  methods: {
    init: function () {
      this.playing = false
      this.wasPlaying = false
      this.pauseForSeek = false
      this.loading = false
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
      this.portrait = false
      this.firstPlay = true

      this.controlBarVisible = true
      this.recentMouseMovement = false
      this.lastClickTime = 0
      if (this.clickTimer != null) {
        clearTimeout(this.clickTimer)
        this.clickTimer = null
      }

      this.onlySlide = false
      this.selectedSlideIndex = 0
      this.fullscreen = false
      this.$refs.video.src = null
    },
    onPlay: function () {
      this.onPlayStateChange()
    },
    onPlaying: function () {
      this.playing = true
      this.loading = false
      this.$refs.video.playbackRate = this.speed
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
      this.$emit('playing')
    },
    onPause: function () {
      if (this.pauseForSeek) {
        this.pauseForSeek = false
        return
      }
      this.playing = false
      this.loading = false
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
      this.onPlayStateChange()
      this.$emit('pause')
    },
    onEnded: function () {
      this.$emit('ended')
    },
    onError: function () {
      if (this.$refs.video && !this.$refs.video.error) {
        return
      }
      this.pauseForSeek = false
      this.playing = false
      this.loading = false
      this.$emit('error')
    },
    onWaiting: function () {
      this.$emit('waiting')
      if (this.loadingTipTimer != null) {
        clearTimeout(this.loadingTipTimer)
        this.loadingTipTimer = null
      }
      this.loadingTipTimer = setTimeout(() => {
        this.loading = true
      }, 3000)
    },
    onLoadedMetaData: function () {
      var videoWidth = this.$refs.video.videoWidth
      var videoHeight = this.$refs.video.videoHeight
      this.portrait = videoHeight > videoWidth
      this.$emit('videoSize', videoWidth, videoHeight)
    },
    onPlayStateChange: function () {
      if (this.$refs.video.ended && !this.$refs.video.paused) {
        this.pause()
      }
      this.computeOpacity()
    },
    computeOpacity: function () {
      const videoIsPaused = this.$refs.video.paused
      if (videoIsPaused || this.recentMouseMovement) {
        this.controlBarVisible = true
        this.$refs.videoContent.style.cursor = ''
      } else {
        setTimeout(() => {
          if (this.playing) {
            this.controlBarVisible = false
            this.$refs.videoContent.style.cursor = 'none'
          }
        }, 1000)
      }
    },
    onVideoMouseMove: function () {
      this.recentMouseMovement = true
      this.computeOpacity()
      clearTimeout(this.mouseMoveTimer)
      var _this = this
      this.mouseMoveTimer = setTimeout(() => {
        _this.onMouseStill()
      }, 3000)
    },
    onVideoMouseLeave: function () {
      this.onMouseStill()
    },
    onMouseStill: function () {
      this.recentMouseMovement = false
      this.computeOpacity()
    },
    onSeekBarStart: function () {
      this.wasPlaying = !this.$refs.video.paused
      if (this.wasPlaying) {
        this.pause()
        this.pauseForSeek = true
      }
    },
    onSeekBarChange: function (progress) {
      clearTimeout(this.mouseMoveTimer)
      this.controlBarVisible = true
      this.mouseMoveTimer = setTimeout(() => {
        this.onMouseStill()
      }, 3000)
      this.$emit('seekTime', parseInt(progress))
    },
    onSeekBarEnd: function () {
      if (this.wasPlaying) {
        this.play()
        this.wasPlaying = false
      }
    },
    clickPlayControl: function (event) {
      if (isMobile()) {
        let nowTime = new Date().getTime()
        if (nowTime - this.lastClickTime < 400) {
          /* 双击 */
          event.preventDefault()
          this.lastClickTime = 0
          if (this.clickTimer != null) {
            clearTimeout(this.clickTimer)
          }
          this.$emit('playControl')
        } else {
          /* 单击 */
          this.lastClickTime = nowTime
          this.clickTimer = setTimeout(() => {
            this.mobileControlToggleEvt()
          }, 400)
        }
      } else {
        this.$emit('playControl')
      }
    },
    clickPlayButton: function () {
      this.$emit('playControl')
    },
    mobileControlToggleEvt: function () {
      const videoIsPaused = this.$refs.video.paused
      if (!this.controlBarVisible) {
        this.controlBarVisible = true
      }
      if (this.controlBarVisible && !videoIsPaused) {
        clearTimeout(this.mouseMoveTimer)
        this.mouseMoveTimer = setTimeout(() => {
          this.controlBarVisible = false
        }, 3000)
      }
    },
    clickPptButton: function () {
      this.onlySlide = !this.onlySlide
    },
    clickFullscreenButton: function () {
      if (this.fullscreen) {
        this.exitFullscreen()
      } else {
        if (this.isSafariMobile()) {
          var video = this.$refs.video
          if (!this.isMediaPlaying(video)) {
            this.$emit('playControl')
            setTimeout(() => {
              if (video.webkitEnterFullScreen) {
                video.webkitEnterFullScreen()
              }
            }, 50)
          } else {
            if (video.webkitEnterFullScreen) {
              video.webkitEnterFullScreen()
            }
          }
        } else {
          this.enterFullscreen(this.$refs.videoContent)
        }
      }
    },
    enterFullscreen(el) {
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    },
    isSafariMobile: function () {
      var ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('iphone') > -1 &&
          ua.indexOf('linux') === -1 && ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
          ua.indexOf('ios') === -1) {
        return true
      } else {
        return false
      }
    },
    isMediaPlaying: function (el) {
      return el.currentTime > 0 && !el.paused && !el.ended && el.readyState > 2
    },
    clickSpeedButton: function () {
      this.$emit('switchSpeed')
    },
    clickForwardButton: function () {
      this.forward()
    },
    clickBackwardButton: function () {
      this.backward()
    },
    clickSlideItem: function (item, index) {
      this.selectedSlideIndex = index
      this.$emit('seekTime', item.beginTime + 200)
    },
    updateMediaItem: function (mediaItem) {
      const { videoUrl, coverUrl, videoSmartUrl, videoM3u8Url } = mediaItem
      if (videoM3u8Url != null && videoM3u8Url !== '' && isSupportM3u8()) {
        this.$refs.video.src = videoM3u8Url
      } else {
        this.$refs.video.src = videoSmartUrl !== '' ? videoSmartUrl : videoUrl
      }
      this.$refs.video.poster = coverUrl
      this.$refs.video.playbackRate = this.speed
      if (mediaItem.width > 0 && mediaItem.height > 0) {
        this.portrait = mediaItem.height > mediaItem.width
        this.$emit('videoSize', mediaItem.width, mediaItem.height)
      }
    },
    getRealCurrentTime: function() {
      return this.$refs.video.currentTime * 1000
    },
    setRealCurrentTime: function(currentTime) {
      this.$refs.video.currentTime = currentTime / 1000
    },
    pause: function () {
      if (this.canPaused) {
        this.$refs.video.pause()
        this.canPaused = false
      }
    },
    play: function () {
      this.canPaused = false
      this.$refs.video.play().then(() => {
        this.canPaused = true
      }).catch(e => {
        console.log(e)
        this.canPaused = false
      })
      if (this.firstPlay) {
        if (!this.fullscreen && isMobile()) {
          this.clickFullscreenButton()
        }
        this.firstPlay = false
      }
    },
    forward: function () {
      if (this.slideList.length > 0) {
        if (this.selectedSlideIndex < this.slideList.length - 1) {
          this.clickSlideItem(this.slideList[this.selectedSlideIndex + 1], this.selectedSlideIndex + 1)
        }
      } else {
        this.$emit('seekTime', this.current + 15000 < this.duration ? this.current + 15000 : this.duration)
      }
    },
    backward: function () {
      if (this.slideList.length > 0) {
        if (this.selectedSlideIndex > 0) {
          this.clickSlideItem(this.slideList[this.selectedSlideIndex - 1], this.selectedSlideIndex - 1)
        }
      } else {
        this.$emit('seekTime', this.current - 15000 > 0 ? this.current - 15000 : 0)
      }
    },
    clickLinkButton: function () {
      const time = parseInt(this.$refs.video.currentTime)
      this.$emit('linkClick', time)
    }
  }
}
</script>
<style scoped lang="scss">
.player {
  display: flex;
  flex-direction: column;
  .video-content {
    background: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    & video {
      width: 100%;
      height: 100%;
    }
    .slide-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: black;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .link-button {
      min-width: 120px;
      max-width: 240px;
      height: 50px;
      line-height: 50px;
      text-decoration: none;
      cursor: pointer;
      padding: 0 12px;
      background: rgba(62,127,255,1);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255,255,255,1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      display: block;
    }
    .play-control {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
      .replay-button {
        cursor: pointer;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        & img {
          width: 16px;
          height: 16px;
        }
        & div {
          margin-left: 9px;
          margin-right: 10px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255,255,255,1);
        }
      }
    }
    .control-bar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50px;
      background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%);
      .progress-bar {
        position: absolute;
        top: 10px;
        left: 16px;
        right: 87px;
        height: 30px;
      }
      .ppt-button {
        position: absolute;
        bottom: 15px;
        right: 52px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        & img {
          width: 20px;
          height: 20px;
        }
      }
      .fullscreen-button {
        position: absolute;
        bottom: 15px;
        right: 16px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        & img {
          width: 20px;
          height: 20px;
        }
      }
      .speed-button {
        position: absolute;
        visibility: hidden;
        width: 30px;
        height: 20px;
        cursor: pointer;
        & img {
          width: 30px;
          height: 20px;
        }
      }
      .forward-button {
        position: absolute;
        visibility: hidden;
        width: 20px;
        height: 20px;
        cursor: pointer;
        & img {
          width: 20px;
          height: 20px;
        }
      }
      .backward-button {
        position: absolute;
        visibility: hidden;
        width: 20px;
        height: 20px;
        cursor: pointer;
        & img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .control-bar-noslide {
      .progress-bar {
        position: absolute;
        top: 10px;
        left: 16px;
        right: 52px;
        height: 30px;
      }
      .ppt-button {
        visibility: hidden;
      }
    }
    .spinner-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-shrink: 1;
      & img {
        width: 57px;
        height: 57px;
        -webkit-animation: changeright 1s linear infinite;
      }
    }
    @-webkit-keyframes changeright {
      0% {
        -webkit-transform: rotate(0deg);
      }
      50% {
        -webkit-transform: rotate(180deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  }
  .video-content:fullscreen {
    .control-bar {
      height: 70px;
      .progress-bar {
        top: 10px;
        left: 16px;
        right: 16px;
        height: 10px;
      }
      .ppt-button {
        bottom: 15px;
        left: 16px;
      }
      .fullscreen-button {
        bottom: 15px;
        right: 16px;
      }
      .speed-button {
        visibility: visible;
        bottom: 15px;
        right: 68px;
      }
      .forward-button {
        visibility: visible;
        bottom: 15px;
        right: 130px;
      }
      .backward-button {
        visibility: visible;
        bottom: 15px;
        right: 182px;
      }
    }
    .control-bar-portrait {
      height: 50px;
      .progress-bar {
        top: 10px;
        left: 16px;
        right: 97px;
        height: 30px;
      }
      .fullscreen-button {
        bottom: 15px;
        right: 16px;
      }
      .speed-button {
        visibility: visible;
        bottom: 15px;
        right: 52px;
      }
      .forward-button {
        visibility: hidden;
      }
      .backward-button {
        visibility: hidden;
      }
    }
  }
  .slide-list {
    height: 77px;
    min-height: 77px;
    background: #1C1C1C;
    padding: 13px 13px 0px 13px;
    display: flex;
    flex-direction: row;
    z-index: 100;
    .slide-list-inner {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      .slide-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>
