<template>
  <div class="main">
    <div class="doc-list">
      <div v-for="(item, index) in list" :key="index" class="doc-item" @click="clickDocItem(item.docId)">
        <img class="sign" src="../../assets/group/video.svg" />
        <div class="info">
          <div class="title">{{ item.name }}</div>
          <div class="time">{{ item.docTime }}</div>
        </div>
        <img class="arrow" src="../../assets/group/arrow.svg" />
      </div>
    </div>
    <brand class="brand" />
    <div v-if="loading" class="loading">
      <img src="../../assets/detail/loading.svg" />
    </div>
  </div>
</template>
<script>
import Brand from '../../components/Brand'
import { getFolderDocList } from '../../api/doc'
import { formatDocTime } from '../../util/time'

export default {
  name: 'Group',
  components: {
    Brand
  },
  data () {
    return {
      list: [],
      loading: false,
      pageNum: 0,
      pageSize: 1000
    }
  },
  mounted () {
    this.getDocList(this.$route.query.fsId, this.$route.query.userId)
  },
  methods: {
    getDocList: function (fsId, userId) {
      this.loading = true
      getFolderDocList({
        fsId: fsId,
        userId: userId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          res.data.data.content.forEach(item => {
            item.docTime = formatDocTime(item.updateTime)
          })
          this.list = res.data.data.content
        } else {
          console.log(res.data.desc)
          // TODO
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    },
    clickDocItem: function (docId) {
      this.$router.push('/detail?docId=' + docId)
    } 
  }
}
</script>
<style scoped lang="scss">
.main {
  background: #F7F7F7;
  position: relative;
  padding-top: 6px;
  height: calc(100vh - 6px);
  font-family: PingFangSC-Regular, PingFang SC;
  .doc-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 85px;
    .doc-item {
      margin: 6px 16px;
      height: 72px;
      background: rgba(255,255,255,1);
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      .sign {
        margin: 16px 12px 0px 16px;
        width: 16px;
        height: 16px;
      }
      .info {
        flex-grow: 1;
        width: 0;
        display: flex;
        flex-direction: column;
        .title {
          margin-top: 12px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(34,34,34,1);
        }
        .time {
          margin-top: 6px;
          font-size: 12px;
          font-weight: normal;
          color: rgba(102,102,102,1);
        }
      }
      .arrow {
        margin: 10px 6px 0px 3px;
        width: 28px;
        height: 28px;
      }
    }
  }
  .brand {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    background: rgba(255,255,255,0.8);
    z-index: 10000;
    & img {
      width: 43px;
      height: 43px;
      -webkit-animation: changeright 1s linear infinite;
    }
  }
  @-webkit-keyframes changeright {
    0% {
      -webkit-transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}
</style>
