<template>
  <div class="button" v-clickoutside="hideMenu">
    <img v-if="speed === 2" @click="clickSpeedButton" class="image" src="../../../assets/web/speed-2.0.svg" />
    <img v-else-if="speed === 1.7" @click="clickSpeedButton" class="image" src="../../../assets/web/speed-1.7.svg" />
    <img v-else-if="speed === 1.5" @click="clickSpeedButton" class="image" src="../../../assets/web/speed-1.5.svg" />
    <img v-else-if="speed === 1.2" @click="clickSpeedButton" class="image" src="../../../assets/web/speed-1.2.svg" />
    <img v-else @click="clickSpeedButton" class="image" src="../../../assets/web/speed-1.0.svg" />
    <div class="menu" v-show="menuVisible">
      <div class="item" :class="speed === 2 ? 'item-active' : ''" @click="clickSelectSpeed(2)">2x</div>
      <div class="item" :class="speed === 1.7 ? 'item-active' : ''" @click="clickSelectSpeed(1.7)">1.7x</div>
      <div class="item" :class="speed === 1.5 ? 'item-active' : ''" @click="clickSelectSpeed(1.5)">1.5x</div>
      <div class="item" :class="speed === 1.2 ? 'item-active' : ''" @click="clickSelectSpeed(1.2)">1.2x</div>
      <div class="item" :class="speed === 1 ? 'item-active' : ''" @click="clickSelectSpeed(1)">1x</div>
    </div>
  </div>
</template>
<script>
import Clickoutside from 'element-ui/src/utils/clickoutside'

export default {
  name: 'SpeedButton',
  directives: { Clickoutside },
  props: {
    speed: {
      type: Number,
      required: true,
      default: 1
    }
  },
  data () {
    return {
      menuVisible: false
    }
  },
  methods: {
    hideMenu: function () {
      this.menuVisible = false
    },
    clickSpeedButton: function () {
      this.menuVisible = !this.menuVisible
    },
    clickSelectSpeed: function (speed) {
      this.menuVisible = false
      this.$emit('selected', speed)
    }
  }
}
</script>
<style scoped lang="scss">
.button {
  position: relative;
  width: 32px;
  height: 24px;
  .image {
    width: 32px;
    height: 24px;
  }
  .menu {
    position: absolute;
    bottom: 30px;
    left: -12px;
    width: 56px;
    background: rgba(255,255,255,1);
    border-radius: 8px;
    padding: 4px 0;
    overflow: hidden;
    .item {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      color: rgba(153,153,153,1);
      &:hover {
        background: rgba(235,235,235,0.5);
      }
    }
    .item-active {
      background: rgba(235,235,235,1);
      color: rgba(51,51,51,1);
    }
  }
}
</style>
