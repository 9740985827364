export function getClass (elem) {
  return (elem.getAttribute && elem.getAttribute('class')) || ''
}

export function hasClass (elem, clzName) {
  var curClass = ' ' + getClass(elem) + ' '
  return curClass.indexOf(' ' + clzName + ' ') > -1
}
export function addClass (elem, clzName) {
  if (!hasClass(elem, clzName)) {
    elem.className = elem.className + ' ' + clzName
  }
}
export function removeClass (elem, clzName) {
  var curClass = ' ' + getClass(elem) + ' '
  elem.className = curClass.replace(' ' + clzName + ' ', ' ').trim()
}
export function adjustClass (clzName, removeClzNames, addClzNames) {
  var curClass = ' ' + clzName + ' '
  if (removeClzNames != null && removeClzNames.length > 0) {
    removeClzNames.forEach(name => {
      curClass = curClass.replace(' ' + name + ' ', ' ')
    })
  }
  if (addClzNames != null && addClzNames.length > 0) {
    addClzNames.forEach(name => {
      if (curClass.indexOf(' ' + name + ' ') === -1) {
        curClass = curClass + ' ' + name
      }
    })
  }
  return curClass
}
