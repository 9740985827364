import request from '../util/request'

export function getDocDetail (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/doc/detail',
    method: 'get',
    params: data
  })
}

export function getFolderDocList(data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/fs/listPage',
    method: 'get',
    params: data
  })
}

export function addDocViews (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/doc/add/views',
    method: 'post',
    params: data
  })
}

export function getDocShareInfo (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/get/shareInfo',
    method: 'get',
    params: data
  })
}

export function verifyDocShareCode (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/share/verify',
    method: 'get',
    params: data
  })
}

export function wechatLogin (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/h5/wechat/login',
    method: 'get',
    params: data
  })
}

export function reportViewRecord (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/viewRecord/report',
    method: 'post',
    params: data
  })
}

export function getDocLinkInfo (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/link/get',
    method: 'get',
    params: data
  })
}

export function updateClick (data = {}) {
  return request({
    url: 'anon/audio/link/click',
    method: 'post',
    params: data
  })
}