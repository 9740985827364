const UserInfoKey = 'user_info'
const ConfigPlayerKey = "config_player"
const ConfigWebIndexKey = "config_web_index"
const ConfigDocPasswordKey = "config_doc_password"
const ConfigWechatUIDKey = "config_wechat_uid"

export function setUserInfo (userInfo) {
  if (userInfo != null) {
    localStorage.setItem(UserInfoKey, JSON.stringify(userInfo))
  }
}

export function getUserInfo () {
  var userInfoStr = localStorage.getItem(UserInfoKey)
  return userInfoStr != null ? JSON.parse(userInfoStr) : null
}

export function removeUserInfo () {
  localStorage.setItem(UserInfoKey, null)
}

export function getUid () {
  var userInfo = getUserInfo()
  return userInfo != null ? userInfo.user.id : null
}

export function getToken () {
  var userInfo = getUserInfo()
  return userInfo != null ? userInfo.token : null
}

export function getSalt () {
  var userInfo = getUserInfo()
  return userInfo != null ? userInfo.salt : null
}

export function loadPlayerSetting () {
  var settingStr = localStorage.getItem(ConfigPlayerKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function savePlayerSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigPlayerKey, JSON.stringify(setting))
  }
}

export function loadWebIndexSetting () {
  var settingStr = localStorage.getItem(ConfigWebIndexKey)
  return settingStr != null ? JSON.parse(settingStr) : {}
}

export function saveWebIndexSetting (setting) {
  if (setting != null) {
    localStorage.setItem(ConfigWebIndexKey, JSON.stringify(setting))
  }
}

export function getWechatUID () {
  return localStorage.getItem(ConfigWechatUIDKey)
}

export function setWechatUID(uid) {
  localStorage.setItem(ConfigWechatUIDKey, uid)
}

export function getDocPassword (docId) {
  var passwordMapStr = localStorage.getItem(ConfigDocPasswordKey)
  var passwordMap = passwordMapStr != null ? JSON.parse(passwordMapStr) : {}
  return passwordMap[docId]
}

export function saveDocPassword (docId, password) {
  var passwordMapStr = localStorage.getItem(ConfigDocPasswordKey)
  var passwordMap = passwordMapStr != null ? JSON.parse(passwordMapStr) : {}
  passwordMap[docId] = password
  localStorage.setItem(ConfigDocPasswordKey, JSON.stringify(passwordMap))
}

export function clearDocPassword (docId) {
  var passwordMapStr = localStorage.getItem(ConfigDocPasswordKey)
  var passwordMap = passwordMapStr != null ? JSON.parse(passwordMapStr) : {}
  delete passwordMap[docId]
  localStorage.setItem(ConfigDocPasswordKey, JSON.stringify(passwordMap))
}
