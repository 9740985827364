<template>
  <div></div>
</template>
<script>
export default {
  name: 'Index',
  mounted () {
  }
}
</script>
<style scoped lang="scss">
</style>
